import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectBox from '../../../ui/selectBox';
import Modal from '../../modal';

import './styles.scss';

function useOutsideAlerter(ref, close) {
    const reactSelectClassnames = [' css-1tvj958-option', ' css-1bdfte4-option'];
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !reactSelectClassnames.includes(event.target.className)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const ChangeStatus = ({ closeModal, statuses, changeStatus, deviceId, statusId }) => {
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);
    const [filtered, setFiltered] = useState(false);
    useEffect(() => {
        const arrayKeyStatuses = Object.keys(statuses).map(item => parseInt(item));
        const index = arrayKeyStatuses.indexOf(statusId);

        if (index > -1) {
            arrayKeyStatuses.splice(index, 1);
        }

        setFiltered(Object.keys(statuses)
            .filter(key => arrayKeyStatuses.map(e => e.toString()).includes(key))
            .reduce((obj, key) => {
                obj[key] = statuses[key];

                return obj;
            }, {}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        }, 300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-change-status': true, open: flag })} ref={wrapperRef}>
                <div className='title'>Change status</div>
                <div className='description'>This will change the status  for all selected units. This change is immediate and will impact the current billing cycle</div>
                <Formik
                    initialValues={{
                        status: { label: statuses[statusId], value: statusId },
                    }}
                    onSubmit={values => {
                        changeStatus(deviceId, parseInt(values.status.value));
                        close();
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          setFieldTouched,
                          setFieldValue,
                      }) => (
                        <Form>
                            <div className='contact-info-box'>
                                <div className='select-box'>
                                    <SelectBox
                                        values={values.status}
                                        name='status'
                                        icon='status'
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        height={56}
                                        paddingTop={5}
                                        marginTop={-1}
                                        withIcon
                                        statuses={filtered}
                                    />
                                </div>
                            </div>
                            <div className='btn-box centered'>
                                <div className='btn-cancel'
                                     onClick={() => close()}
                                >cancel</div>
                                <button type='submit' className='btn-save'>CHANGE</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

ChangeStatus.propTypes = {
    closeModal: PropTypes.func,
    changeStatus: PropTypes.func,
    statuses: PropTypes.array,
    deviceId: PropTypes.string,
    statusId: PropTypes.number,
};

export default ChangeStatus;
