import React from 'react';
import classNames from 'classnames';
import './style.scss';

const hamburger = ({ setMenuVisibility, isActive }) => {
    return (
        <div
            className={classNames({ hamburger: true, 'hamburger-boring': true, 'is-active': isActive })}
            onClick={() => setMenuVisibility(!isActive)}
        >
            <span className='hamburger-box'>
                <span className='hamburger-inner' />
            </span>
        </div>
    );
};

export default hamburger;