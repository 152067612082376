import * as types from '../constants';
import { callApi } from '../utils/client';
import { NotificationManager } from 'react-notifications';

const showNotification = (message, type) => {
    const parsedMessage = JSON.stringify(message);
    if (type === 'error') {
        NotificationManager.error('An error occurred while sending the request', undefined, 1000 * 60);
    } else if (type === 'success') {
        NotificationManager.success('Message was successfully sent', undefined, 1000 * 60);
    }
};

/**
 *
 * @returns {Function}
 */
export function getPlans() {
    return dispatch => {
        dispatch({
            type: types.FETCH_PLANS,
        });

        return callApi('plans', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_PLANS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_PLANS_FAIL,
                payload: 'error', // todo refactoring: error.message returns -> request to https://api.mastrack-crm.sapient.pro/v1/plans/all failed, reason: request to https://api.mastrack-crm.sapient.pro/v1/plans/all failed, reason:
            });
        });
    };
}

/**
 *
 * @param fields
 */
export function customPlan(fields) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CUSTOM_PLAN,
        });

    return callApi('plans/request-custom', 'POST', { ContactForm: { ...fields } })
            .then(json => {
                dispatch({
                    type: types.FETCH_CUSTOM_PLAN_SUCCESS,
                    payload: json,
                });
                showNotification(json, 'success');
            }).catch(error => {
                dispatch({
                    type: types.FETCH_CUSTOM_PLAN_FAIL,
                    payload: error, // todo the same error message as on plans/all
                });
                showNotification(error, 'error');
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function resetCustomPlan() {
    return dispatch => {
        dispatch({
            type: types.FETCH_RESET_CUSTOM_PLAN,
        });
    };
}
