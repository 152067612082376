import * as types from '../constants';
import { callApi } from '../utils/client';
import { NotificationManager } from 'react-notifications';
import successAlert from '../utils/successAlert';
import { logOut } from '../actions/auth';

const showNotification = (message, type) => {
    if (type === 'error') {
        NotificationManager.error(message, undefined, 1000 * 60);
    } else if (type === 'success') {
        NotificationManager.success(message, undefined, 1000 * 60);
    }
};


/**
 *
 * @param id
 * @param size
 * @param page
 * @returns {Function}
 */
export function removeUser(id = 1, size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_USER,
        });

        return callApi(`users/${id}`, 'DELETE')
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_USER_SUCCESS,
                    payload: json,
                });
                dispatch(getUsers(null, size, page));
            }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_USER_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function addUserSuccess() {
    return dispatch => {
        dispatch({
            type: types.FETCH_SUCCESS_ADD_USER,
        });
    };
}

/**
 *
 * @param field
 * @returns {Function}
 */
export function addUser(user, id) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_USER,
        });

        return callApi(`company/${id}/users`, 'POST', user)
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_USER_SUCCESS,
                    payload: json,
                });
                showNotification(json.data, 'success');
            }).catch(error => {
            dispatch({
                type: types.FETCH_ADD_USER_FAIL,
                payload: error.message,
            });
                showNotification(error.message, 'error');
        });
    };
}
/**
 *
 * @returns {Function}
 */
export function getRoles() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_ROLES,
        });

        return callApi('company/child-roles', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_ROLES_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_ROLES_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never>}
 */
export function getOrganisation() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_ORGANISATION,
        });

        return callApi('company', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_ORGANISATION_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_ORGANISATION_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @param id
 * @param size
 * @param page
 * @returns {Function}
 */
export function getUsers(id = 1, size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_USERS,
        });

        return callApi(`users?size=${size}&page=${page}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_USERS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_USERS_FAIL,
                payload: error.message,
            });
        });
    };
}

export function searchUsers(id = 1, size = 1, page = 0, data) {
    const { first_name, last_name } = data;
    return dispatch => {
        dispatch({
            type: types.FETCH_USERS,
        });

        return callApi(`users?size=${size}&page=${page}&first_name=${first_name}&last_name=${last_name}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_USERS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_USERS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function getUser(id = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_USER,
        });

        return callApi(`users/${id}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_USER_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_USER_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param field
 * @param callback
 * @returns {Function}
 */
export function updateUser(id = 1, field, callback = () => {}) {
    return dispatch => {
        dispatch({
            type: types.FETCH_UPDATE_USER,
        });

        return callApi(`users/${id}`, 'PUT', field )
            .then(json => {
                successAlert('Changes saved');
                dispatch({
                    type: types.FETCH_UPDATE_USER_SUCCESS,
                    payload: json,
                });
                callback(json);
            }).catch(error => {
                console.log(error);
            dispatch({
                type: types.FETCH_UPDATE_USER_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param field
 * @returns {Function}
 */
export function updateAuthUser(id = 0, field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_AUTH_UPDATE_USER,
        });

        return callApi(`users/${id}`, 'PUT', field )
            .then(json => {
                dispatch({
                    type: types.FETCH_AUTH_UPDATE_USER_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_AUTH_UPDATE_USER_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function clearError() {
    return dispatch => {
        dispatch({
            type: types.FETCH_CLEAN_ERROR,
        });
    };
}

/**
 *
 * @param fields
 * @returns {Function}
 */
export function changePassword(fields) {
    const token = JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('authToken')).access_token;

    return dispatch => {
        dispatch({
            type: types.FETCH_CHANGE_PASSWORD,
        });

        return callApi('users/change-password','PUT', fields)
            .then(json => {
                dispatch({
                    type: types.FETCH_CHANGE_PASSWORD_SUCCESS,
                    payload: json,
                });
                if(json) dispatch(logOut(token));
                showNotification('Password was changed', 'success');
            }).catch(error => {
            dispatch({
                type: types.FETCH_CHANGE_PASSWORD_FAIL,
                payload: error.message,
            });
            showNotification(error.message, 'error');
        });
    };
}
