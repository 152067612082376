import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const Pagination = ({ handleClick, total, current }) => {
    useEffect(() => {
        handleClick(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total]);

    return (
        <div className='pagination'>
            {(total > 1 && total <= 4) && (
                Array(total).fill('').map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleClick(index + 1)}
                        className={classNames({ active: (index + 1) === current })}
                    >
                        {index + 1}
                    </div>
                ))
            )}
            {total > 4 && (current < 3 ) && (
                <>
                    <div onClick={() => handleClick(1)} className={classNames({ tcm: true, active: 1 === current })}>1</div>
                    <div onClick={() => handleClick(2)} className={classNames({ active: 2 === current })}>2</div>
                    <div onClick={() => handleClick(3)} className={classNames({ active: 3 === current })}>3</div>
                    <div>...</div>
                    <div onClick={() => handleClick(total)}>{total}</div>
                </>
            )}

            {total > 4 && (current >= 3 && current <= total - 2) && (
                <>
                    <div onClick={() => handleClick(1)} className={classNames({ tcm: true, active: 1 === current })}>1</div>
                    <div>...</div>
                    <div onClick={() => handleClick(current - 1)}>{current - 1}</div>
                    <div onClick={() => handleClick(current)} className={classNames({ active: current })}>{current}</div>
                    <div onClick={() => handleClick(current + 1)} className=''>{current + 1}</div>
                    <div>...</div>
                    <div className='tcm1' onClick={() => handleClick(total)}>{total}</div>
                </>
            )}

            {total > 4 && (current > total - 2 ) && (
                <>
                    <div onClick={() => handleClick(1)} className={classNames({ tcm: true, active: 1 === current })}>1</div>
                    <div>...</div>
                    <div onClick={() => handleClick(total - 2)} className={classNames({ active: total - 2 === current })}>{total - 2}</div>
                    <div onClick={() => handleClick(total - 1)} className={classNames({ active: total - 1 === current })}>{total - 1}</div>
                    <div onClick={() => handleClick(total)} className={classNames({ active: total === current })}>{total}</div>
                </>
            )}
        </div>
    );
};

Pagination.propTypes = {
    handleClick: PropTypes.func,
    total: PropTypes.number,
    current: PropTypes.number,
};

export default Pagination;
