/**
 *
 */
export function brainTree(addCard, token, callback) {
    const client = require('braintree-web/client');
    const hostedFields = require('braintree-web/hosted-fields');
    const submitBtn = document.getElementById('my-submit');
    const form = document.getElementById('my-sample-form');
    const cardNumber = document.getElementById('credit-card-number');

    const clientDidCreate = (err, client) => {
        hostedFields.create({
            client,
            styles: {
                input: {
                    'font-size': '16px',
                    color: '#fff',
                },
                '.cvv': {
                    'font-size': '12px',
                },
                '.expirationDate': {
                    'font-size': '12px',
                },
                '.number': {
                    'font-family': 'monospace',
                },

                '.valid': {
                    color: 'green',
                }
            },
            fields: {
                number: {
                    selector: '#card-number',
                    maxCardLength: 16,
                },
                cvv: {
                    selector: '#cvv',
                },
                expirationDate: {
                    selector: '#expiration-date',
                },
            },
        }, hostedFieldsDidCreate);
    };

    client.create({
        authorization: token,
    }, clientDidCreate);

    const hostedFieldsDidCreate = (err, hostedFields) => {
        submitBtn.addEventListener('click', submitHandler.bind(null, hostedFields));
        submitBtn.removeAttribute('disabled');
    };

    const submitHandler = (hostedFields, event) => {
        event.preventDefault();
        submitBtn.setAttribute('disabled', 'disabled');
        hostedFields && hostedFields.tokenize( (err, payload) => {
            if (err) {
                submitBtn.removeAttribute('disabled');
                console.error(err);
            } else {
                form['payment_method_nonce'].value = payload.nonce;
                addCard(payload, callback);
            }
        });
    };
}
