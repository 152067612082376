export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';

export const FETCH_SIGN_UP = 'FETCH_SIGN_UP';
export const FETCH_SIGN_UP_SUCCESS = 'FETCH_SIGN_UP_SUCCESS';
export const FETCH_SIGN_UP_FAIL = 'FETCH_SIGN_UP_FAIL';

export const FETCH_SIGN_UP_FIRST_STEP = 'FETCH_SIGN_UP_FIRST_STEP';
export const FETCH_SIGN_UP_FIRST_STEP_SUCCESS = 'FETCH_SIGN_UP_FIRST_STEP_SUCCESS';
export const FETCH_SIGN_UP_FIRST_STEP_FAIL = 'FETCH_SIGN_UP_FIRST_STEP_FAIL';

export const FETCH_FORGOT_PASSWORD = 'FETCH_FORGOT_PASSWORD';
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS';
export const FETCH_FORGOT_PASSWORD_FAIL = 'FETCH_FORGOT_PASSWORD_FAIL';

export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD';
export const FETCH_RESET_PASSWORD_SUCCESS = 'FETCH_RESET_PASSWORD_SUCCESS';
export const FETCH_RESET_PASSWORD_FAIL = 'FETCH_RESET_PASSWORD_FAIL';

export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
export const FETCH_LOGOUT_FAIL = 'FETCH_LOGOUT_FAIL';

export const FETCH_VERIFICATION = 'FETCH_VERIFICATION';
export const FETCH_VERIFICATION_SUCCESS = 'FETCH_VERIFICATION_SUCCESS';
export const FETCH_VERIFICATION_FAIL = 'FETCH_VERIFICATION_FAIL';

export const FETCH_CREATE_PASSWORD = 'FETCH_CREATE_PASSWORD';
export const FETCH_CREATE_PASSWORD_SUCCESS = 'FETCH_CREATE_PASSWORD_SUCCESS';
export const FETCH_CREATE_PASSWORD_FAIL = 'FETCH_CREATE_PASSWORD_FAIL';

export const FETCH_CLEAN_LOGIN_ERROR = 'FETCH_CLEAN_LOGIN_ERROR';

export const FETCH_CLOSE_MODAL = 'FETCH_CLOSE_MODAL';
export const FETCH_OPEN_MODAL = 'FETCH_OPEN_MODAL';