import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import Loader from '../../../ui/loader';
import InputBox from '../../../ui/inputBox';
import { bindActionCreators } from 'redux';
import AuthLayout from '../authLayout';
import { resetPassword } from '../../../../actions/auth';
import { getUrlParams } from '../../../../constants/app';
import history from "../../../../utils/history";

const ResetPassword = ({ actions: { resetPassword }, loading }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(getUrlParams().token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthLayout>
            <Formik
                initialValues={{
                    password: '',
                    confirm_password: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        password: Yup.string().required('Password is required').matches(
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                        ),
                        confirm_password: Yup.string()
                            .oneOf([Yup.ref('password'), null], 'Passwords must match')
                            .required('Password confirm is required'),
                    })
                }
                onSubmit={field => {
                    const redirectToLogin = () => history.replace('/login');
                   resetPassword(field.password, token, redirectToLogin);
                }}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className='form-box'>
                            <div className='title'>Welcome to Mobile Asset Solutions</div>
                            <div className='description'>Reset password</div>
                            <div className='input-box'>
                                <InputBox
                                    label='New password'
                                    icon='lock'
                                    name='password'
                                    type='password'
                                    setFieldValue={setFieldValue}
                                />
                                <ErrorMessage name='password' className='error-field' component='div' />
                            </div>
                            <div className='input-box'>
                                <InputBox
                                    label='Confirm password'
                                    icon='lock'
                                    name='confirm_password'
                                    type='password'
                                    setFieldValue={setFieldValue}
                                />
                                <ErrorMessage name='confirm_password' className='error-field' component='div' />
                            </div>
                            <div className='btn-link'>
                                {loading && (
                                    <div className='loading-square'>
                                        <div className='box'>
                                            <Loader />
                                        </div>
                                    </div>
                                )}
                                <button type='submit'>send</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

ResetPassword.propTypes = {
    actions: PropTypes.shape({
        resetPassword: PropTypes.func,
    }),
    loading: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{loading: Requireable<(...args: any[]) => any> | resetPassword | boolean}}
 */
function mapStateToProps(state) {
    return {
        loading: state.auth.resetPassword,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({resetPassword: resetPassword}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            resetPassword,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
