import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { clearLoginError } from '../../../actions/auth';
import { renderIcon } from '../../../utils/renderIcon';
import img from '../../../assets/img/login.png';
import './styles.scss';

const AuthLayout: React.FC = ({ children }) => {
    const dispatch = useDispatch();
    // @ts-ignore
    const loginError = useSelector(state => state.auth.error);

    React.useEffect(() => {
        if (loginError) {
            NotificationManager.error(`${loginError}`, 'Error', 5000, () => {
                dispatch(clearLoginError());
            });
            dispatch(clearLoginError());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginError]);

    return (
        <div className='auth-layout'>
            <div className='auth-content'>
                {children}
            </div>
            <div className='photo' style={{ backgroundImage: `url(${img})` }}>
                {renderIcon('logo')}
            </div>
        </div>
    );
};

export default AuthLayout;
