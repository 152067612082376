import { useState, useEffect, useRef } from 'react';

const RESIZING_DELAY_MS = 500;

/**
 *
 * @returns {number}
 */
export function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    const [resizing, setResizing] = useState(false);
    const timerRef = useRef(null);

    useEffect(() => {
        const handleResizingDelay = () => {
            if(timerRef.current) {
                clearTimeout();
            }

            timerRef.current = setTimeout(() => {
                setResizing(false);
            }, RESIZING_DELAY_MS);
        };

        const handleResize = () => {
            setWidth(window.innerWidth);
            setResizing(true);
            handleResizingDelay();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return { width, resizing };
}
