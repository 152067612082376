import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as PropTypes from 'prop-types';

import SelectBox from '../../../ui/selectBox';
import Loader from '../../../ui/loader';

import { liveActionTypeGlobal, liveActionTypeUnit } from '../../../../constants/app';

import './styles.scss';
import LiveActionRules from '../liveActionDetail/liveActionRules';
import successAlert from '../../../../utils/successAlert';

const FormLiveAction = ({
    action,
    loading,
    liveAction = false,
    id = null,
    setDetectActionForm,
    detectActionForm,
    loadingSetRuleAction,
    loadingUpdateRuleAction,
    updateRuleAction,
    setRuleAction,
    localRules,
    localActions,
    removeRuleAction,
}) => {
    const { name, type, status } = liveAction;
    const [changedFields, setChangedFields] = useState([]);
    const [isRulesChanged, setIsRulesChanged] = useState(false);
    const [isActionChanged, setIsActionChanged] = useState(false);
    const [listRules, setListRules] = useState([]);
    const [changedRulesRows, setChangedRulesRows] = useState([]);

    setDetectActionForm(isActionChanged || isRulesChanged);

    const detectChanges = fields => {
        let changed = false;

        if(id !== null) {
            fields.forEach(field => {
                if (liveAction[field.name] !== undefined && liveAction[field.name] !== field.value) {
                    changed = true;
                } else {
                    setChangedFields(changedFields.filter(localField => localField.name !== field.name));
                }
            });
        } else {
            if(fields.length > 0) {
                changed = true;
            }
        }

        setIsActionChanged(changed);
    };

    useEffect(() => {
        if(liveAction && Array.isArray(liveAction.rules)) {
            setListRules(liveAction.rules.map((item, index ) => {
                const changed = changedRulesRows.find(value => value.index === index);
                const isSameLength = changed && changed.action.length === liveAction.rules[index].actions.length;
                const isValid = !changed || isSameLength;

                return{
                    id: item.rule.id,
                    rule: { value: item.rule.id, label: item.rule.name, rules_id: item.id, newElement: false },
                    rules_id: item.id,
                    action: isValid ? item.actions.map(el => (
                        { value: parseInt(el.id), label: el.name, rules_id: item.id }
                    )) : changed.action,
                };
            }));
        }

        if(changedRulesRows.length > 0 || changedFields.length > 0) {
            successAlert('Changes saved');
            setChangedRulesRows([]);
            detectChanges([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveAction]);

    const saveForm = (row) => {
        const actionToSave = {
            action_id: [],
        };

        listRules.forEach((item, index) => {
            if(index === row.index) {
                actionToSave.rule_id = row.hasOwnProperty('rule') ? row.rule.value : item.rule.value;
                if(row.action && row.action.length > 0) {
                    row.action.forEach(element => {
                        actionToSave.action_id.push(element.value);
                    });
                }else{
                    item.action.forEach(element => {
                        actionToSave.action_id.push(element.value);
                    });
                }
                actionToSave.live_action_rule_id = item.rules_id;
            }
        });
        if(row.action && actionToSave.action_id.length === 0) {
            row.action.forEach(element => {
                actionToSave.action_id.push(element.value);
            });
        }

        if(!actionToSave.live_action_rule_id) {
            actionToSave.live_action_id = id;
            actionToSave.rule_id = row.rule.value;
            setRuleAction(actionToSave);
        }
        else{
            updateRuleAction(actionToSave);
        }
    };



    const saveLiveAction = (values) => {
        console.log(changedRulesRows);
        const field = { ...values, type: parseInt(values.type.value) };

        id ? action(id, field) : action(field);

        if( changedRulesRows.length > 0) {
            changedRulesRows.forEach(saveForm);
            setIsRulesChanged(false);
          //  setChangedRulesRows([]);
        }
    };

    const initialTypeObj = {
        label: '',
        value: '',
    };

    const initialType = parseInt(type) === 1 ? liveActionTypeGlobal : liveActionTypeUnit;

    return (
        <Formik
            initialValues={{
                name: name ? name : '',
                type:  liveAction ? initialType : initialTypeObj,
                status: status >= 0 ? status : 1,
            }}
            validationSchema={
                Yup.object().shape({
                    name: Yup.string().required('Action name is required'),
                    type: Yup.object().shape({
                        value: Yup.string().required('Type is required'),
                    }),
                })
            }
            onSubmit={values => saveLiveAction(values)}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  submitForm,
              }) => {
                const customChangeSelect = (name, value) => {
                    const newChangedFields = [
                        ...changedFields.filter(item => item.name !== name),
                        {
                            name,
                            value: value.value,
                        },
                    ];

                    setChangedFields(newChangedFields);
                    setFieldValue(name, value);
                    detectChanges(newChangedFields);
                };

                const customChangeInput = event => {
                    const { name, value } = event.target;

                    const newChangedFields = [
                        ...changedFields.filter(item => item.name !== name),
                        {
                            name,
                            value,
                        },
                    ];

                    setChangedFields(newChangedFields);
                    setFieldValue(name, value);
                    detectChanges(newChangedFields);
                };

                const customChangeRadio = (name,value) => {
                    const newChangedFields = [
                        ...changedFields.filter(item => item.name !== name),
                        {
                            name,
                            value,
                        },
                    ];

                    setChangedFields(newChangedFields);
                    setFieldValue(name, value);
                    detectChanges(newChangedFields);
                };

                return (
                    <Form>
                        <div className='btn-box'>
                            <div className='item'>
                                <div className='action'>
                                    <label
                                        htmlFor='name'
                                        className='name'>action name:</label>
                                    <Field
                                        type='text'
                                        name='name'
                                        id='name'
                                        onChange={event => customChangeInput(event)} />
                                    <ErrorMessage
                                        name='name'
                                        className='error-field'
                                        component='div' />
                                </div>
                            </div>
                            <div className='item'>
                                <div className='type'>
                                    <label
                                        className='name'
                                        htmlFor='typeselect'>type:</label>
                                    <div className='custom-select-box'>
                                        <SelectBox
                                            id='typeselect'
                                            values={values.type}
                                            name='type'
                                            onChange={(name, value) => customChangeSelect(name, value)}
                                            onBlur={setFieldTouched}
                                            defaultSelect
                                            height={34}
                                            paddingLeft={5}
                                            paddingTop={1}
                                            marginTop={-1}
                                            statuses={[liveActionTypeGlobal, liveActionTypeUnit]}
                                        />
                                        <ErrorMessage name='type[value]' className='error-field' component='div' />
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='status'>
                                    <label className='name' htmlFor='status'>status:</label>
                                    <div className='buttons' id='status'>
                                        <label
                                            htmlFor='enable'
                                            className={
                                                classNames({
                                                    button: true,
                                                    active: parseInt(values.status) === 1,
                                                })
                                            }
                                        >
                                            <input
                                                type='radio'
                                                name='status'
                                                id='enable'
                                                value={1}
                                                checked={values.status === 1}
                                                onChange={() => customChangeRadio('status', 1)}
                                            />Enable
                                        </label>
                                        <label
                                            htmlFor='disable'
                                            className={
                                                classNames({
                                                    button: true,
                                                    active: parseInt(values.status) === 0,
                                                })
                                            }
                                        >
                                            <input
                                                type='radio'
                                                name='status'
                                                id='disable'
                                                value={0}
                                                checked={values.status === 0}
                                                onChange={() => customChangeRadio('status', 0)}
                                            />Disable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={
                            classNames({
                                'btn-live': true,
                                active: detectActionForm,
                            })}
                        >
                        {id && <LiveActionRules
                            listRules={listRules}
                            loadingSetRuleAction={loadingSetRuleAction}
                            loadingUpdateRuleAction={loadingUpdateRuleAction}
                            localRules={localRules}
                            localActions={localActions}
                            setIsRulesChanged={setIsRulesChanged}
                            isRulesChanged={isRulesChanged}
                            changedRulesRows={changedRulesRows}
                            setChangedRulesRows={setChangedRulesRows}
                            remove={removeRuleAction}
                        />}
                            {loading
                                ? <div className='load-btn'><Loader width={20} height={20} /></div>
                                : <button type='button' onClick={() => detectActionForm ? submitForm() : null}>{id ? 'update ' : 'create '}live action</button>
                            }
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

FormLiveAction.prototype = {
    loading: PropTypes.bool,
    action: PropTypes.func,
    liveAction: PropTypes.object,
    id: PropTypes.number,
    setDetectActionForm: PropTypes.func,
    detectActionForm: PropTypes.bool,
    loadingSetRuleAction: PropTypes.bool,
    loadingUpdateRuleAction: PropTypes.bool,
    updateRuleAction: PropTypes.func,
    setRuleAction: PropTypes.func,
    localRules: PropTypes.array,
    localActions: PropTypes.array,
    setIsOpenRemoveItem: PropTypes.func,
    setDeleteId: PropTypes.func,
    removeRuleAction: PropTypes.func,
};

export default FormLiveAction;
