import React, { useRef, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import Modal from '../../../ui/modal';
import InputBox from '../../../ui/inputBox';
import Loader from '../../../ui/loader';

import './styles.scss';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const CustomPlan = ({ closeModal, customPlan, formLoading, resetCustomPlan }) => {
    const button = useRef();
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        },300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-change-plan': true, open: flag })} ref={wrapperRef}>
                <div className='title'>Request custom plan</div>
                <div className='change-container'>
                    <Formik
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            phone: '',
                            email: '',
                            message: '',
                        }}
                        validationSchema={
                            Yup.object().shape({
                                first_name: Yup.string().required('First name is required'),
                                email: Yup.string().required('E-mail is required'),
                            })
                        }
                        onSubmit={values => {
                            customPlan(values);
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                          }) => (
                            <Form>
                                <div className='contact-info-box'>
                                    <div className='form-input'>
                                        <InputBox
                                            label='First name'
                                            icon='men'
                                            name='first_name'
                                            type='text'
                                            setFieldValue={setFieldValue}
                                            value={values.first_name}
                                        />
                                        <ErrorMessage name='first_name' className='error-field' component='div' />
                                    </div>
                                    <div className='form-input'>
                                        <InputBox
                                            label='Last name'
                                            icon='men'
                                            name='last_name'
                                            type='text'
                                            setFieldValue={setFieldValue}
                                            value={values.last_name}
                                        />
                                    </div>
                                    <div className='form-input'>
                                        <InputBox
                                            label='Phone number'
                                            icon='phone'
                                            name='phone'
                                            type='text'
                                            setFieldValue={setFieldValue}
                                            value={values.phone}
                                        />
                                    </div>
                                    <div className='form-input'>
                                        <InputBox
                                            label='E-mail'
                                            icon='email'
                                            name='email'
                                            type='email'
                                            setFieldValue={setFieldValue}
                                            value={values.email}
                                        />
                                        <ErrorMessage name='email' className='error-field' component='div' />
                                    </div>
                                    <div className='form-input'>
                                        <Field component='textarea' name='additional' placeholder='Message...' />
                                    </div>
                                </div>
                                <button type='submit' className='hide' ref={button}>Send</button>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className='btn-box centered'>
                    <div
                        className='btn-cancel'
                        onClick={() => { close(); resetCustomPlan(); }}
                    >cancel</div>

                        <div className='load-box'>
                            {formLoading
                                ? (
                                    <div className='load-container'>
                                        <Loader />
                                    </div>
                                ) : (
                                <div
                                    className='btn-send'
                                    onClick={() => button.current ? button.current.click() : null }
                                >
                                    Send
                                </div>
                            )}
                        </div>
                </div>
            </div>
        </Modal>
    );
};

CustomPlan.propTypes = {
    closeModal: PropTypes.func,
    resetCustomPlan: PropTypes.func,
    formLoading: PropTypes.bool,
    customPlan: PropTypes.func,
};

export default CustomPlan;
