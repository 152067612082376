import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const SelectBox = ({
                       values,
                       label,
                       onChange,
                       name,
                       onBlur,
                       icon,
                       withIcon = false,
                       isMulti = false,
                       defaultSelect = false,
                       height,
                       paddingLeft,
                       paddingTop,
                       marginTop,
                       statuses,
                       stat,
                       backgroundColor,
                       arrowDown = true,
                       active = false,
                       isClearable = false,
                       defaultValue
                   }) => {
    const [isActiveLabel, setIsActiveLabel] = useState(active || false);
    const select = useRef(null);
    const handleChange = value => {
        onChange(name, value);
        select.current.blur();
    };
    const handleBlur = () => {
        onBlur(name, true);
    };

    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? !backgroundColor && '#FFFFFF' || backgroundColor : backgroundColor ? backgroundColor : 'rgba(248,248,248, .8)',
                transition: 'all .3s ease-in',
                height: height ? height : 59,
                paddingLeft: paddingLeft ? paddingLeft : '41px',
                fontSize: '13px',
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 600,
                paddingTop: paddingTop ? paddingTop : '12px',
                boxShadow: 'none',
                borderColor: 'transparent',
                borderRadius: '2px',
                borderBottomLeftRadius: isFocused ? '0' : '2px',
                borderBottomRightRadius: isFocused ? '0' : '2px',
                '&:hover': { borderColor: 'transparent' },
            };
        },
        option: (styles, { data, isDisabled, isFocused }) => {
            return {
                ...styles,
                color: '#222',
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontSize: '13px',
                fontWeight: '500',
                paddingLeft: '28px',
                backgroundColor: isFocused ? '#E5E5E5' : null,
                '&:hover': { backgroundColor: '#E5E5E5' },
            };
        },
        indicatorSeparator: (styles) => {
            return {
                ...styles,
                backgroundColor: 'transparent',
            };
        },
        indicatorsContainer: (styles) => {
            return {
                ...styles,
                marginTop: marginTop ? marginTop : '-12px',
            };
        },
        input: (styles) => {
            return {
                ...styles,
                outline: 'none',
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                marginTop: '0px',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
                zIndex: '10',
            };
        },
        dropdownIndicator: (styles) => {
            return {
                ...styles,
                display: arrowDown ? 'block' : 'none',
                color: '#222',
                '&:hover': { color: '#222' },
            };
        },
    };

    const [options, setOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const selectOptions = stat ? deviceOptions : options;


    useEffect(() => {
        if(statuses && statuses.length > 0) {
            setOptions(statuses);
        }else if(statuses && Object.keys(statuses).length > 0) {
            Object.keys(statuses).map(key => (
                setOptions(prevState => ( [...prevState, { value: key, label: statuses[key] }]))
            ));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statuses]);



    useEffect(() => {
        if(stat && stat.length > 0) {
            stat.forEach((org) => {
                const { id, name } = org;
                setDeviceOptions(prevState => ( [...prevState, { value: id, label: name }]));
            });
        }else if(stat && Object.keys(stat).length > 0) {
            Object.keys(stat).map(key => (
                setDeviceOptions(prevState => ( [...prevState, { value: key, label: stat[key] }]))
            ));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stat]);

    return (
        <div className='custom-select'>
            {!defaultSelect && (
                <div className={classNames({ label: true, active: isActiveLabel })}>{label}</div>
            )}
            {withIcon && (
                <div className='icon'>{renderIcon(icon)}</div>
            )}
            <Select
                options={selectOptions}
                value={values}
                isMulti={isMulti}
                isClearable={isClearable}
                ref={select}
                name={name}
                onMenuOpen={() => setIsActiveLabel(true)}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=''
                className='custom-select-field'
                styles={colourStyles}
            />
        </div>
    );
};

export default SelectBox;
