import React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import * as PropTypes from 'prop-types';

import InputBox from '../../../ui/inputBox';

const ResetPassword = ({ changePassword }) => {
    return (
        <Formik
            initialValues={{
                old_password: '',
                new_password: '',
                confirm_password: '',
            }}
            validationSchema={
                Yup.object().shape({
                    old_password: Yup.string().required('Current password is required'),
                    new_password: Yup.string().required('Password is required').matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                    ),
                    confirm_password: Yup.string()
                        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
                        .required('Password confirm is required'),
                })
            }
            onSubmit={(values, { resetForm }) => {
                changePassword({
                    ...values,
                    user_id: JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')).user_id,
                });
                resetForm();
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
              }) => (
                <Form>
                    <div className='contact-info-box'>
                        <div className='form-input'>
                            <InputBox
                                label='Current password'
                                icon='lockBlack'
                                name='old_password'
                                type='password'
                                setFieldValue={setFieldValue}
                                value={values.old_password}
                            />
                            <ErrorMessage name='old_password' className='error-field' component='div' />
                        </div>
                        <div className='form-input'>
                            <InputBox
                                label='New password'
                                icon='lockWhite'
                                name='new_password'
                                type='password'
                                setFieldValue={setFieldValue}
                                value={values.new_password}
                            />
                            <ErrorMessage name='new_password' className='error-field' component='div' />
                        </div>
                        <div className='form-input'>
                            <InputBox
                                label='Repeat password'
                                icon='lockWhite'
                                name='confirm_password'
                                type='password'
                                setFieldValue={setFieldValue}
                                value={values.confirm_password}
                            />
                            <ErrorMessage name='confirm_password' className='error-field' component='div' />
                        </div>
                    </div>
                    <button type='submit' className='change-password'>
                        Change password
                    </button>
                </Form>
            )}
        </Formik>
    );
};

ResetPassword.propTypes = {
    changePassword: PropTypes.func,
};

export default ResetPassword;
