import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { addDevice } from '../../../../actions/devices';

import './styles.scss';

const FormDevice = ({ actions: { addDevice } }) => {

    const [ changedFields, setChangedFields ] = useState([]);
    const [ deviceData, setDeviceData ] = useState({});

    const formFields = [
        { name: 'sim', title: 'ICCID' },
        { name: 'phone', title: 'Phone' },
        { name: 'msisdn', title: 'MSISDN' },
        { name: 'serial', title: 'Serial' },
        { name: 'ip', title: 'Ip' },
        { name: 'sim_type', title: 'Sim type' },
        { name: 'sim_carrier', title: 'Sim carrier' },
        { name: 'imsi', title: 'IMSI' },
        { name: 'eid', title: 'EID' },
    ];

    const getInitialValues = () => {
        return formFields.reduce((acc, item) => {
            return {
                ...acc,
                [item.name]: '',
            };
        }, {});
    };

    useEffect(() => {
        if (Object.keys(deviceData).length !== 0) {
            addDevice(deviceData);
        }
    }, [deviceData]);

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={
                Yup.object().shape({
                    sim: Yup.mixed(), // ICCID
                    msisdn: Yup.number().required('MSISDN is required').typeError('msisdn number must consist only of numbers'), // MSISDN - phone number
                    serial: Yup.mixed(), // serial
                    ip: Yup.mixed(), // Ip
                    sim_type: Yup.string().matches(/^[0-9a-zA-Z]+$/, "Only letters and numbers are allowed"), // Sim type
                    sim_carrier: Yup.string().matches(/^[0-9a-zA-Z]+$/, "Only letters and numbers are allowed"), // Sim carrier
                    imsi: Yup.number().typeError('imsi must consist only of numbers'),
                    eid: Yup.number().typeError('eid must consist only of numbers'),
                })
            }
            onSubmit={(values) => setDeviceData(values) }
        >
            {
                ({
                     setFieldValue,
                     submitForm,
                }) => {
                    const customChangeInput = event => {
                        const { name, value } = event.target;

                        const newChangedFields = [
                            ...changedFields.filter(item => item.name !== name),
                            {
                                name,
                                value,
                            },
                        ];

                        setChangedFields(newChangedFields);
                        setFieldValue(name, value);
                    };

                    return (
                        <Form>
                            <div className='device-box' onChange={event => customChangeInput(event)}>
                                {formFields.map(({ name, title }) => {
                                        return (
                                            <div className='item' key={ name }>
                                                <div className='action'>
                                                    <div className='name'>{ title }:</div>
                                                    <Field type='text' name={ name }/>
                                                    <ErrorMessage name={ name }
                                                                  className='error-field'
                                                                  component='div' />
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className='btn-device'>
                                <button type='submit' className='btn'
                                        onClick={() => submitForm()}>Add new</button>
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            addDevice,
        }, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(FormDevice);