import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Sidebar from '../components/ui/sidebar';
import Header from '../components/ui/header';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isActive, setIsActive] = useState(false);
    const closeMenu = () => setIsActive(false);
    const setMenuVisibility = visible => setIsActive(visible);

    return (
      <Route
          {...rest}
          render={props =>
              (sessionStorage.getItem('authToken') || localStorage.getItem('authToken')) ? (
                <>
                    <Header isActive={isActive} setMenuVisibility={setMenuVisibility} />
                    <Sidebar isActive={isActive} closeMenu={closeMenu} />
                    <Component {...props} />
                </>
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
          }
      />
  );
};
