import React, { useState } from 'react';
import classNames from 'classnames';

import * as PropTypes from 'prop-types';

import { renderIcon } from '../../../utils/renderIcon';
import UpdateOtherInfo from '../../ui/modals/updateOtherInfo';

import './styles.scss';

const AdditionalInfo = ({ item, warn, selected, updateOtherInfo }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenChangeInfoModal, setIsChangeInfoModal] = useState(false);

    return (
        <>
            {isOpenChangeInfoModal && (
                <UpdateOtherInfo closeModal={setIsChangeInfoModal} data={item} updateOtherInfo={updateOtherInfo} />
            )}
            <div className='additional-box'>
                <div className='title-btn'>
                    <div className='title'>{item.title}:</div>
                    <div className='btn-box options'>
                        <div className='more' onClick={() => setIsOpenModal(!isOpenModal)}>{renderIcon('more')}</div>
                        <div className={classNames({ 'svg-option-box': true, open: isOpenModal })}>
                            <div className='line'>
                                <div className='icon' onClick={() => { warn(true); selected(item.id); }}>{renderIcon('trash')}</div>
                                <div className='name' onClick={() => { warn(true); selected(item.id); }}>remove</div>
                            </div>
                            <div className='line' onClick={() => setIsChangeInfoModal(!isOpenChangeInfoModal)}>
                                <div className='icon'>{renderIcon('pencil')}</div>
                                <div className='name'>edit</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='description'>{item.info}</div>
            </div>
        </>
    );
};

AdditionalInfo.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string,
        info: PropTypes.string,
    }),
    warn: PropTypes.func,
    selected: PropTypes.func,
    updateOtherInfo: PropTypes.func,
};

export default AdditionalInfo;
