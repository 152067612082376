import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import HeaderTab from '../../../ui/headerTabs';
import AdditionalInfo from '../../../ui/additionalInfo';
import RemoveItem from '../../../ui/modals/removeItem';
import Loader from '../../../ui/loader';

import { getOtherInfo, addOtherInfo, removeOtherInfo, updateOtherInfo } from '../../../../actions/devices';

import { optionalDeviceTabs } from '../../../../constants/app';

import './styles.scss';

const Info = ({
    actions : {
        getOtherInfo,
        addOtherInfo,
        removeOtherInfo,
        updateOtherInfo,
    },
    match: {
        params: {
            id,
        },
    },
    otherInfo,
    loadingOtherInfo,
    loadingAddOtherInfo,
}) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [removeItem, setRemoveItem] = useState(false);
    const [selectRemoveItem, setSelectRemoveItem] = useState(false);


    useEffect(() => {
        getOtherInfo(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    return (
        <div className='device-info-container'>
            {removeItem && (
                <RemoveItem remove={removeOtherInfo} id={selectRemoveItem} closeModal={setRemoveItem} />
            )}
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <HeaderTab optionTabs={optionalDeviceTabs(id)} activeTab={3} />
                <div className='container-detail'>
                    <div className='additional-list'>
                        {loadingOtherInfo ?
                            (
                                <div className='load-box'>
                                    <Loader orange/>
                                </div>
                            ) : otherInfo.map(item => (
                                <AdditionalInfo
                                    key={item.id}
                                    item={item}
                                    warn={setRemoveItem}
                                    selected={setSelectRemoveItem}
                                    updateOtherInfo={updateOtherInfo}
                                />))
                        }

                        {!loadingOtherInfo && otherInfo.length === 0 && (
                            <div className='load-box js-zero'>
                                <div>There are no other info</div>
                            </div>
                        )}
                    </div>
                    <div className={classNames({ 'add-additional': true, open: isOpenModal })}>
                        <Formik
                            initialValues={{
                                title: '',
                                info: '',
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    title: Yup.string().required('Title is required'),
                                })
                            }
                            onSubmit={(values, form) => {
                                const field = { ...values, device_id: parseInt(id) };

                                addOtherInfo(field);
                                form.resetForm();
                            }}
                        >
                            {() => (
                                <Form>
                                    <div className='form-box'>
                                        <div className='title'>Add new field</div>
                                        <div className='field-box'>
                                            <Field type='text' name='title' placeholder='Title name' />
                                            <ErrorMessage name='title' className='error-field' component='div' />
                                        </div>
                                        <div className='field-box'>
                                            <Field component='textarea' name='info' placeholder='Field text...' />
                                        </div>
                                        <div className='btn-box centered'>
                                            <div className='cancel' onClick={() => setIsOpenModal(!isOpenModal)}>cancel</div>
                                            {loadingAddOtherInfo ? (
                                                <div className='load-form'>
                                                    <Loader orange width={24} height={24} />
                                                </div>
                                                ) : (
                                                <button type='submit'>ADD field</button>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className='show-form-mobile' onClick={() => setIsOpenModal(!isOpenModal)}>add new field</div>
                </div>
            </PageContainer>
        </div>
    );
};

Info.propTypes = {
    actions: PropTypes.shape({
        getOtherInfo: PropTypes.func,
        addOtherInfo: PropTypes.func,
        removeOtherInfo: PropTypes.func,
        updateOtherInfo: PropTypes.func,
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    otherInfo: PropTypes.array,
    loadingOtherInfo: PropTypes.bool,
    loadingAddOtherInfo: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{otherInfo: Requireable<any[]> | Array, loadingOtherInfo: Requireable<boolean> | boolean, loadingAddOtherInfo: Requireable<boolean> | boolean}}
 */
function mapStateToProps(state) {
    return {
        otherInfo: state.devices.otherInfo,
        loadingOtherInfo: state.devices.loadingOtherInfo,
        loadingAddOtherInfo: state.devices.loadingAddOtherInfo,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({removeOtherInfo: removeOtherInfo, getOtherInfo: getOtherInfo, addOtherInfo: addOtherInfo, updateOtherInfo: updateOtherInfo}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getOtherInfo,
            addOtherInfo,
            removeOtherInfo,
            updateOtherInfo,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
