import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Loader from "../loader";
import {renderIcon} from '../../../utils/renderIcon';

import './styles.scss';
import fetch from "cross-fetch";

const EmptyBankAccount = ({
                              primary = false, setShowBankCard = () => {
    }, setShowCreditCardActive = () => {
    }, setShowBankCardActive = () => {
    }, addACH, plaidToken, creditCard, isLoading, setLoadingValue = () => {
    }
                          }) => {

    let linkHandler;
    if (plaidToken) {
        linkHandler = window.Plaid.create({
            env: 'sandbox',
            clientName: 'Stripe/Plaid Test',
            token: plaidToken.link_token,
            product: ['auth'],
            selectAccount: true,
            onSuccess: function (public_token, metadata) {
                // Send the public_token and account ID to your app server.
                addACH({
                    'public_token': public_token,
                    'account_id': metadata.account_id,
                    'mask': metadata.account.mask
                }, updateCardShowingState);
            },
            onExit: function (err, metadata) {
                // The user exited the Link flow.
                if (err != null) {
                    // The user encountered a Plaid API error prior to exiting.
                }
            },
        });

    }

    // useEffect(() => {
    //     setLoadingValue(false);
    // }, [isLoading])

    const updateCardShowingState = (isLoading) => {
        setLoadingValue(isLoading)
        setShowCreditCardActive(true);
    };


    const handleClick = () => {
        if (primary) {
            linkHandler.open();
        } else {
            setShowBankCard(true);
        }
    };

    return (
        <div className={classNames({'empty-card': true, 'black-card': !primary, linkButton: primary})}>
            <div className='content' onClick={handleClick}>
                <div className='center-content'>
                    {isLoading ? <div className='icon'><Loader/></div> :
                        <>
                            <div className={classNames({trash: true})} onClick={() => {
                                handleClick();
                            }}>{renderIcon('pen')}</div>
                            <div className='icon'>{primary ? renderIcon('bank') : renderIcon('card')}</div>
                            <div className='name'>{primary ? 'Add Bank account' : 'Add Credit card'}</div>
                        </>

                    }

                </div>
                <div className='wave'>{primary ? renderIcon('orangeWave') : renderIcon('blackWave')}</div>
            </div>
        </div>
    );
};


export default EmptyBankAccount;