import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '../../modal';
import ModalPlan from './plan';

import './styles.scss';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const ChangePlan = ({ closeModal, plans, currentPlan, changePlan, deviceId, setIsOpenChangeStatusModal}) => {
    const newPlans = plans.filter( item => item.id !== currentPlan?.id);
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        },300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-change-plan': true, open: flag })} ref={wrapperRef}>
                <div className='title'>Choose Plan</div>
                <div className='description'>This will change the assigned plan for all selected units. This change is immediate and will impact the current billing cycle</div>
                {!currentPlan && <div className='alert'>Choose plan before changing device status</div>}
                <div className='list-plans'>
                    {currentPlan &&
                        (<div className='item'>
                            <div className='name'>Current plan</div>

                            <ModalPlan
                                item={currentPlan}
                                hover={false}
                                hideBtn={false}
                            />
                        </div>)
                    }
                    <div className='item'>
                        <div className='name second'>available plans</div>
                        {newPlans.map((item, index) => (
                            <div key={index} className='el-k'>
                                <ModalPlan
                                    setIsOpenChangeStatusModal={setIsOpenChangeStatusModal}
                                    currentPlan={currentPlan}
                                    item={item}
                                    changePlan={changePlan}
                                    deviceId={deviceId}
                                    closeModal={closeModal}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='btn-box'>
                    <div className='btn-cancel'
                         onClick={() => close()}
                    >cancel</div>
                </div>
            </div>
        </Modal>
    );
};

ChangePlan.propTypes = {
    closeModal: PropTypes.func,
    changePlan: PropTypes.func,
    deviceId: PropTypes.string,
    plans: PropTypes.array,
    currentPlan: PropTypes.shape({
        price_mb: PropTypes.number,
        mrc: PropTypes.number,
        included_data: PropTypes.number,
    }),
};

export default ChangePlan;
