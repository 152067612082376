import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import Modal from '../../../ui/modal';
import Loader from '../../loader';

import './styles.scss';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const Message = ({ closeModal, send, deviceId, loading, responseSms, resetSendSms }) => {
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setTimeout(() => resetSendSms(), 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseSms]);

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        },300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-new-message': true, open: flag })} ref={wrapperRef}>
                <Formik
                    initialValues={{
                        message: '',
                    }}
                    validationSchema={
                        Yup.object().shape({
                            message: Yup.string().required('Message is required'),
                        })
                    }
                    onSubmit={(values, { resetForm }) => {
                        send(deviceId, values.message);
                        resetForm();
                    }}
                >
                    {() => {
                        return (
                            <Form>
                                <div className='contact-info-box'>
                                    <div className='title'>new message</div>
                                    <div className='textarea-box'>
                                        <Field component='textarea' name='message' placeholder='Field text...' />
                                        <ErrorMessage name='message' className='error-field' component='div' />
                                        {responseSms && (
                                            <div className='form-success'>{responseSms.status}</div>
                                        )}
                                    </div>
                                    <div className='btn-box centered'>
                                        <div className='btn-cancel'
                                             onClick={() => close()}
                                        >cancel</div>
                                        {loading ? (
                                            <div className='load-box'>
                                                <Loader orange />
                                            </div>
                                        ) : (
                                            <button type='submit' className='btn-save'>CHANGE</button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Modal>
    );
};

Message.propTypes = {
    closeModal: PropTypes.func,
    resetSendSms: PropTypes.func,
    send: PropTypes.func,
    loading: PropTypes.bool,
    activate: PropTypes.bool,
    responseSms: PropTypes.string,
};

export default Message;
