import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { logOut } from '../../../actions/auth';
import { renderIcon } from '../../../utils/renderIcon';
import Hamburger from '../hamburger';

import './style.scss';

const Header = ({ actions: { logOut }, isActive, setMenuVisibility }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(JSON.parse(sessionStorage.getItem('authToken') || localStorage.getItem('authToken')).access_token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className='header'>
            <div className='show-sidebar'>
                <Hamburger isActive={isActive} setMenuVisibility={setMenuVisibility}/>
            </div>
            <div className='logo'>{renderIcon('logo')}</div>
            <div className='logout' onClick={() => logOut(token) }>{renderIcon('logout')}</div>
        </div>
    );
};

Header.propTypes = {
    isActive: PropTypes.bool,
    setMenuVisibility: PropTypes.func,
    actions: PropTypes.shape({
       logOut: PropTypes.func,
    }),
};

/**
 *
 * @param dispatch
 * @returns {{actions: ({logOut: logOut}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            logOut,
        }, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(Header);
