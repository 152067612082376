import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import * as PropTypes from 'prop-types';

import InputBox from '../../../ui/inputBox';
import Loader from '../../../ui/loader';

const ContactInfo = ({ updateAuthUser, user, loading }) => {
    const submitButton = useRef(null);

    return (
        <Formik
            initialValues={{
                first_name: user.first_name ? user.first_name : '',
                last_name: user.last_name ? user.last_name : '',
                phone: user.phone ? user.phone : '',
                email: user.email ? user.email : '',
            }}
            onSubmit={values => {
                updateAuthUser(user.user_id, values);
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
              }) => (
                <Form onChange={() => submitButton.current.click()} className='form-update-auth-user'>
                    {loading && (
                        <div className='loading-box'>
                            <Loader orange />
                        </div>
                    )}
                    <div className='contact-info-box'>
                        <div className='form-input'>
                            <InputBox
                                label='First name'
                                icon='men'
                                name='first_name'
                                type='text'
                                setFieldValue={setFieldValue}
                                value={values.first_name}
                            />
                        </div>
                        <div className='form-input'>
                            <InputBox
                                label='Last name'
                                icon='men'
                                name='last_name'
                                type='text'
                                setFieldValue={setFieldValue}
                                value={values.last_name}
                            />
                        </div>
                        <div className='form-input'>
                            <InputBox
                                label='Phone number'
                                icon='phone'
                                name='phone'
                                type='text'
                                setFieldValue={setFieldValue}
                                value={values.phone}
                                maskPhone
                            />
                        </div>
                        <div className='form-input'>
                            <InputBox
                                label='E-mail'
                                icon='email'
                                name='email'
                                type='email'
                                setFieldValue={setFieldValue}
                                value={values.email}
                            />
                        </div>
                    </div>
                    <button type='submit' ref={submitButton} className='hide-btn-submit' />
                </Form>
            )}
        </Formik>
    );
};

ContactInfo.propTypes = {
    updateAuthUser: PropTypes.func,
    user: PropTypes.object,
    loading: PropTypes.bool,
};

export default ContactInfo;
