import React, { useState } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Dropdown from '../../../ui/dropDown';

import { renderIcon } from '../../../../utils/renderIcon';

const ModalPlan = ({ item, hover = true, hideBtn = true, changePlan, currentPlan, deviceId, closeModal, setIsOpenChangeStatusModal}) => {
    const [toggleClick, setToggleClick] = useState(false);

    function redirectToChangeStatus() {
        if (!currentPlan) {
            changePlan(deviceId, item?.id);
            closeModal(false);
            setIsOpenChangeStatusModal(true);
        }
        else {
            changePlan(deviceId, item?.id);
            closeModal(false);
        }
    }


    return (
        <div className={classNames({ 'plan-box' : true, without: !hover })}>
            <div className='name-with-btn'>
                <div className='name-plan'>{item?.name}</div>
                <div className={classNames({ btn : true, opened: toggleClick })} onClick={() => setToggleClick(!toggleClick) }>
                    <div className='more'>More</div>
                    <div className='icon'>{renderIcon('arrow')}</div>
                </div>
            </div>
            <Dropdown open={toggleClick}>
                <div className='plan__text'>{item?.description}</div>
            </Dropdown>
            <div className={classNames({ 'list-detail': true, opened: toggleClick })}>
                {item?.mrc && (
                    <div className='line'>
                        <div className='line-name'>MRC</div>
                        <div className='line-description'>{item?.mrc}</div>
                    </div>
                )}
                {item?.price_mb && (
                    <div className='line'>
                        <div className='line-name'>Price mb</div>
                        <div className='line-description'>{item?.price_mb}</div>
                    </div>
                )}
                {item?.included_data && (
                    <div className='line'>
                        <div className='line-name'>Included Data</div>
                        <div className='line-description'>{item?.included_data}</div>
                    </div>
                )}
                {item?.type && (
                    <div className='line'>
                        <div className='line-name'>Type</div>
                        <div className='line-description'>{item?.type}</div>
                    </div>
                )}
            </div>
            {hideBtn && (
                <div className='choose' onClick={redirectToChangeStatus}>
                    CHANGE PLAN
                </div>
            )}
        </div>
    );
};

ModalPlan.propTypes = {
    item: PropTypes.object,
    hover: PropTypes.bool,
    hideBtn: PropTypes.bool,
    deviceId: PropTypes.string,
    changePlan: PropTypes.func,
    closeModal: PropTypes.func,
};

export default ModalPlan;

