import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import HeaderTab from '../../../ui/headerTabs';
import Loader from '../../../ui/loader';

import BillingAddressForm from './billingAddressForm';
import ShippingAddressFrom from './shippingAddressFrom';

import {
    getAddress,
    deleteAddress,
    updateAddress,
    updateBillingCheckbox,
    createShippingAddress,
    clearBillinAddress,
} from '../../../../actions/settings';

import { optionalSettingTabs } from '../../../../constants/app';

import './styles.scss';

const defaultFields = {
    first_name: '',
    last_name: '',
    phone: '',
    alt_phone: '',
    email: '',
    country: '',
    zip_code: '',
    city: '',
    address: '',
    alt_address: '',
};

const CompanyData = ({
    actions: {
        updateAddress,
        getAddress,
        deleteAddress,
        updateBillingCheckbox,
        createShippingAddress,
    },
    billingAddress,
    loading,
    updateLoading,
}) => {
    const companyId = JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')).company_id;
    const [shippingForms, setShippingForms] = useState([]);
    const [useBillingAddress, setUseBillingAddress] = useState(billingAddress.use_billing_address || 0);
    const [disabled, setDisable] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
          dispatch(clearBillinAddress());
        };
    },[])

    useEffect(() => {
        getAddress(companyId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

   useEffect(() => {
     if (
       billingAddress &&
       billingAddress.shipping &&
       billingAddress.shipping.length
     ) {
       const newFormsList = billingAddress.shipping.map(
         (shippingAddress, index) => ({
           key: `line-${index + 1}`,
           values: shippingAddress,
         })
       );

       setShippingForms(newFormsList.slice(0, 1));
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [billingAddress.shipping && billingAddress.shipping?.length]);

    useEffect(() => {
        setDisable(!(billingAddress.shipping?.length+1 > shippingForms.length + 1));
    }, [shippingForms.length])

    const deleteCurrentForm = (id) => {
        setShippingForms(shippingForms.filter(item => item.key !== id))
    };

    const handleRemoveAddress = (id, key) => {
        deleteCurrentForm(key);
        id && deleteAddress(id);
    };

    return (
        <div className='company-data'>
            <div className="settings-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <HeaderTab optionTabs={optionalSettingTabs} activeTab={1} />
                <div className={classNames({ 'account-detail': true, show: shippingForms.length > 1 })}>
                    {loading && (
                        <div className='load-box'>
                            <Loader orange />
                        </div>
                    )}
                    <div className='title'>Billing Address:</div>

                    {billingAddress && billingAddress.billing && (
                        <BillingAddressForm
                            billingAddress={billingAddress.billing}
                            updateAddress={updateAddress}
                            updateLoading={updateLoading}
                            companyId={companyId}
                        />
                    )}
                    <div className='title-with-button'>
                        <div className='title'>Shipping Address:</div>
                        <div className={classNames({ 'same-abs-square': billingAddress.use_billing_address })}>
                            <button disabled={disabled} className='add-shipping'
                                 onClick={() => {
                                     if(!billingAddress.use_billing_address && billingAddress.shipping?.length+1 > shippingForms.length) {
                                         setShippingForms(
                                             [
                                                 ...shippingForms,
                                                 {
                                                     key: `line-${shippingForms.length > 0 ? shippingForms[shippingForms.length - 1].key.slice(5) * 1 + 1 : 1}`,
                                                     values: defaultFields,
                                                 },
                                             ]
                                         );
                                     }
                                 }}
                            >add new</button>
                        </div>
                    </div>
                    <div className='form-shipping-content'>
                        {billingAddress && (
                            <div className='check-field'>
                                <input
                                    className='styled-checkbox'
                                    id='billingAddress'
                                    type='checkbox'
                                    checked={billingAddress.use_billing_address}
                                    name='use_billing_address'
                                    onChange={() => {
                                        setUseBillingAddress(!useBillingAddress);
                                        updateBillingCheckbox(companyId, !useBillingAddress);
                                    }}
                                />
                                <label htmlFor='billingAddress'>Same as billing address</label>
                            </div>
                        )}
                        {shippingForms.map((form, index) => (
                                <div
                                    className={classNames({ 'same-abs-square': billingAddress.use_billing_address })}
                                    key={index}
                                >
                                    <ShippingAddressFrom
                                        shippingAddress={form}
                                        removeAddress={handleRemoveAddress}
                                        createShippingAddress={createShippingAddress}
                                        companyId={companyId}
                                        deleteCurrentForm={deleteCurrentForm}
                                        updateAddress={updateAddress}
                                    />
                                </div>
                            )
                        )}
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

CompanyData.propTypes = {
    actions: PropTypes.shape({
        updateAddress: PropTypes.func,
        getAddress: PropTypes.func,
        deleteAddress: PropTypes.func,
        updateBillingCheckbox: PropTypes.func,
        createShippingAddress: PropTypes.func,
    }),
    billingAddress: PropTypes.shape({
        billing_address: PropTypes.object,
        shipping: PropTypes.object,
    }),
    loading: PropTypes.bool,
    updateLoading: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{updateLoading: *, billingAddress: *, loading: boolean, user: *}}
 */
function mapStateToProps(state) {
    return {
        billingAddress: state.settings.billingAddress,
        loading: state.settings.loadingAddress,
        updateLoading: state.settings.updateAddress,
        user: state.user,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({createShippingAddress: createShippingAddress, updateAddress: updateAddress, getAddress: getAddress, deleteAddress: deleteAddress, updateBillingCheckbox: updateBillingCheckbox}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getAddress,
            updateAddress,
            updateBillingCheckbox,
            createShippingAddress,
            deleteAddress,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyData);
