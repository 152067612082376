import React from 'react';
import * as PropTypes from 'prop-types';

import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const Search = ({ setSearchRequest, searchRequest }) => {
  return (
    <form className="form-search" onSubmit={(event) => event.preventDefault()}>
      <input
        name="search-input"
        type="text"
        placeholder="Search"
        value={searchRequest}
        onChange={(event) => setSearchRequest(event.target.value)}
      />
      <button type="submit">{renderIcon("search")}</button>
    </form>
  );
};

Search.propTypes = {
    searchDevices: PropTypes.func,
};

export default Search;
