import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AuthLayout from '../authLayout';
import Loader from '../../../ui/loader';
import { verification } from '../../../../actions/auth';

import './styles.scss';


const Verification = () => {
    const dispatch = useDispatch();
    const { verificationToken } = useParams<{ verificationToken: string }>();

    // @ts-ignore
    const loading = useSelector(state => state.auth.verificationLoading);

    React.useEffect(() => {
        dispatch(verification(verificationToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AuthLayout>
        <div className='form-box'>
            <div className='title'>Welcome to Mobile Asset Solutions</div>
            {loading && (
                <Loader orange width={50} height={50} />
            )}
        </div>
    </AuthLayout>;
};

export default Verification;
