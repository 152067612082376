import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { addDevices } from '../../../../../actions/devices';
import {convertToFormData} from "../../fileUtils";

const UploadInputDeviceMobile = ({ actions: { addDevices } }) => {
    const [ deviceData, setDeviceData ] = useState({});

    const supportedFormats = ['csv'];

    const handleSubmit = (data) => {
        data.validateForm()
            .then(item => {
                if (Object.keys(item).length === 0) {
                    addDevices(convertToFormData(deviceData));
                }
        });
    };

    const extractExtension = (str) => {
        return str.split('.').slice(-1).join('');
    };

    return (
        <Formik
            initialValues={{ file: null }}
            validationSchema={
                Yup.object().shape({
                    file: Yup.mixed()
                        .required('A file is required')
                        .test(
                            'format-check',
                            'only csv file is allowed',
                            value => supportedFormats.indexOf( extractExtension(value.name) ) !== -1
                        ),
                })
            }
            onSubmit={handleSubmit}
        >
            {
                ({
                     submitForm,
                     ...props
                 }) => {
                    const customChangeInput = event => {
                        const { files } = event.target;

                        setDeviceData(files[0]);
                        props.setFieldValue('file', files[0]);
                    };

                    return (
                        <Form>
                            <div className='device-box'>
                                <div className='item upload-input'>
                                     <input
                                        type='file'
                                        name='file'
                                        className='upload-file-input'
                                        id='upload-file-input'
                                        onChange={(event) => {
                                            customChangeInput(event);
                                        }}
                                     />
                                    <div className='download-box'>
                                        <a className='link-download' href = "/examples/testDevices.csv" download="">Download an example</a>
                                    </div>
                                    <ErrorMessage name='file'
                                                  className='error-field'
                                                  component='div' />
                                </div>
                            </div>
                            <div className='btn-device'>
                                <button type='submit'
                                        onClick={() => handleSubmit(props)}>Add new</button>
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            addDevices,
        }, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(UploadInputDeviceMobile);