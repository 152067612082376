import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom'

import './styles.scss';

const HeaderTab = ({ optionTabs, activeTab }) => {
    return (
        <div className='header-tabs'>
            {optionTabs.map((item, index) => {
                return (
                    <Link
                        to={item.url}
                        key={index}
                        className={classNames({ element: true, active: activeTab === index })}
                    >
                        {item.name}
                    </Link>
                );
            })}
        </div>
    );
};

HeaderTab.propTypes = {
    optionTabs: PropTypes.array,
    activeTab: PropTypes.number,
};

export default HeaderTab;
