import React from 'react';
import {Route, Link, useLocation} from 'react-router-dom';
import routes from '../../../constants/routes';
import {renderIcon} from '../../../utils/renderIcon';

import './styles.scss';

const Breadcrumbs = () => (
    <div className='breadcrumbs'>
        <ul className='container'>
            <Route path='/:path' component={BreadcrumbsItem}/>
        </ul>
    </div>
);

const arr = ['/devices','/plans','/billing','/settings/account','/settings/company-data','/settings/manage-users','/settings/api-access','/live-actions']

export const BreadcrumbsItem = ({match}) => {
    const loc = useLocation();
    let path = match.params.path;
    let removeHyphen = path.replace('-', ' ');
    return (
        <>
            {/*<li className={match.isExact ? 'breadcrumb-active' : undefined} >*/}
            <li customAttribute={removeHyphen}>
                <Link to={match.url || ''}>
                    {!arr.includes(loc.pathname) && renderIcon('back')}
                    {removeHyphen}
                </Link>
            </li>
            {/*<Route path={`${match.url}/:path`} component={BreadcrumbsItem} />*/}
        </>
    );
};

export default Breadcrumbs;
