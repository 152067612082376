import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { forgotPassword } from '../../../../actions/auth';
import AuthLayout from '../authLayout';
import Loader from '../../../ui/loader';
import InputBox from '../../../ui/inputBox';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const url = `${window.location.origin}/reset-password`;

    const forgotPasswordSuccess = useSelector(state => state.auth.forgotPassword)
    const loading = useSelector(state => state.auth.forgotPasswordLoading)


    return (
        <AuthLayout>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        email: Yup.string().required('Email is required'),
                    })
                }
                onSubmit={field => {
                    dispatch(forgotPassword(field.email, url));
                }}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className='form-box'>
                            <div className='title'>Welcome to Mobile Asset Solutions</div>
                            <div className='description'>Forgot password</div>
                            <div className='input-box'>
                                <InputBox
                                    label='Email'
                                    icon='email'
                                    name='email'
                                    type='email'
                                    setFieldValue={setFieldValue}
                                />
                                <ErrorMessage name='email' className='error-field' component='div' />
                            </div>
                            <div className='btn-link'>
                                {forgotPasswordSuccess
                                    ? <div className='text-success'>{forgotPasswordSuccess.message}</div>
                                    : (
                                        <>
                                            {loading && (
                                                <div className='loading-square'>
                                                    <div className='box'>
                                                        <Loader />
                                                    </div>
                                                </div>
                                            )}
                                            <button type='submit'>send</button>
                                        </>
                                    )
                                }

                                <div className='sign'>
                                    Go back to <Link to='/login'><span>Login</span></Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

export default ForgotPassword;
