export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const FETCH_UPDATE_USER = 'FETCH_UPDATE_USER';
export const FETCH_UPDATE_USER_SUCCESS = 'FETCH_UPDATE_USER_SUCCESS';
export const FETCH_UPDATE_USER_FAIL = 'FETCH_UPDATE_USER_FAIL';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_GET_ROLES = 'FETCH_GET_ROLES';
export const FETCH_GET_ROLES_SUCCESS = 'FETCH_GET_ROLES_SUCCESS';
export const FETCH_GET_ROLES_FAIL = 'FETCH_GET_ROLES_FAIL';

export const FETCH_GET_ORGANISATION = 'FETCH_GET_ORGANISATION';
export const FETCH_GET_ORGANISATION_SUCCESS = 'FETCH_GET_ORGANISATION_SUCCESS';
export const FETCH_GET_ORGANISATION_FAIL = 'FETCH_GET_ROLES_FAIL';

export const FETCH_ADD_USER = 'FETCH_ADD_USER';
export const FETCH_ADD_USER_SUCCESS = 'FETCH_ADD_USER_SUCCESS';
export const FETCH_ADD_USER_FAIL = 'FETCH_ADD_USER_FAIL';
export const FETCH_SUCCESS_ADD_USER = 'FETCH_SUCCESS_ADD_USER';

export const FETCH_REMOVE_USER = 'FETCH_REMOVE_USER';
export const FETCH_REMOVE_USER_SUCCESS = 'FETCH_REMOVE_USER_SUCCESS';
export const FETCH_REMOVE_USER_FAIL = 'FETCH_REMOVE_USER_FAIL';

export const FETCH_AUTH_UPDATE_USER = 'FETCH_AUTH_UPDATE_USER';
export const FETCH_AUTH_UPDATE_USER_SUCCESS = 'FETCH_AUTH_UPDATE_USER_SUCCESS';
export const FETCH_AUTH_UPDATE_USER_FAIL = 'FETCH_AUTH_UPDATE_USER_FAIL';

export const FETCH_CLEAN_ERROR = 'FETCH_CLEAN_ERROR';
