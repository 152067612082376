import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {preventEvent, convertToFormData, fileSize, fileType} from "../fileUtils";

import {assignDevices} from '../../../../../src/actions/devices';
import {getOrganisation} from '../../../../../src/actions/user';

import SelectBox from '../../../ui/selectBox';

import fileIcon from "../../../../assets/svg/fileType.svg";
import uploadIcon from '../../../../assets/svg/upload.svg';

const UploadCSVDevices = ({
                              actions: {
                                  assignDevices,
                                  getOrganisation,
                              },
                              organisation = [],
                          }) => {
    const [selectOrganisation, setSelectOrganisation] = useState({});

    const supportedFormats = ['csv'];

    const extractExtension = (str) => {
        return str.split('.').slice(-1).join('');
    };

    useEffect(() => {
        setSelectOrganisation(organisation?.records);
    }, [organisation]);

    useEffect(() => {
        getOrganisation();
    }, [getOrganisation]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);

    const validateFile = (file) => {
        const validTypes = ['text/csv'];
        if (validTypes.indexOf(file.type) === -1) {
            return false
        }
        return true
    }

    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles(prevArray => [...prevArray, files[i]])
            } else {
                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage('Only csv file is allowed');
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }

        }
    }

    const removeFile = (name) => {
        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        setValidFiles([...validFiles]);

        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles([...selectedFiles]);

        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
        fileInputRef.current.files = new DataTransfer().files;

    }

    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file, current, idx, array) => {
            const x = file.find(item => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);

    }, [selectedFiles]);

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length && validFiles.length < 1) {
            handleFiles(files)
        } else {
            setErrorMessage('You already choose file')
        }
    }

    const fileInputRef = useRef();

    const fileInputClicked = () => {
        !validFiles.length && fileInputRef.current.click();
    }

    return (
        <Formik
            initialValues={{
                file: null,
                company: {
                    label: '',
                    value: '',
                },
            }}
            validationSchema={
                Yup.object().shape({
                    file: Yup.mixed()
                        .required('A file is required')
                        .test(
                            'format-check',
                            'only csv file is allowed',
                            value => supportedFormats.indexOf(extractExtension(value.name)) !== -1
                        ),
                    company: Yup.object().required('Company Name is required').test(
                        'check-value-existing',
                        'Company name is required',
                        value => !!value.value
                    ),
                })
            }
            onSubmit={(values, {validateForm}) => {
                validateForm()
                    .then(item => {
                        if (Object.keys(item).length === 0) {
                            assignDevices(convertToFormData(values));
                        }
                    });
            }}
        >
            {
                ({
                     submitForm,
                     ...formProps
                 }) => {
                    const isReadyToUpload = unsupportedFiles.length === 0 && validFiles.length > 0;
                    const hasUnsupportedFiles = unsupportedFiles.length > 0;
                    const customChangeFileInput = () => {
                        if (fileInputRef.current.files.length) {
                            handleFiles(fileInputRef.current.files)
                        }
                        formProps.setFieldValue('file', fileInputRef.current.files[0]);
                    };

                    return (
                        <div className='form-upload'>
                            <Form>
                                <div className="container-upload">
                                    <div className="drop-container"
                                         onDragOver={preventEvent}
                                         onDragEnter={preventEvent}
                                         onDragLeave={preventEvent}
                                         onDrop={fileDrop}
                                         onClick={fileInputClicked}
                                    >
                                        <div className="drop-message">
                                            {
                                                isReadyToUpload
                                                    ? (
                                                        <button
                                                            type="submit"
                                                            className="file-upload-btn"
                                                            onClick={() => formProps.handleSubmit}
                                                        > Upload Files </button>
                                                    )
                                                    : (
                                                        hasUnsupportedFiles
                                                            ? (
                                                                <span className='file-error-message'>
                                                                Please remove all unsupported files.
                                                            </span>
                                                            )
                                                            : (
                                                                <>
                                                                    <img className="upload-icon" src={uploadIcon} draggable={false}/>
                                                                    Drag & Drop files here or click to upload
                                                                </>
                                                            )
                                                    )
                                            }
                                        </div>
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            className="file-input"
                                            multiple
                                            onChange={customChangeFileInput}
                                        />
                                    </div>
                                    <div className='download-box'>
                                        <a
                                            className='link-download'
                                            href="/examples/testAssignDevice.csv"
                                            download=""
                                        >Download an example</a>
                                    </div>
                                    <div className="file-display-container">
                                        {
                                            validFiles.map((file) =>
                                                <div className="file-status-bar" key={file.name + file.size}>
                                                    <div>
                                                        <img className="file-type-logo" src={fileIcon}/>
                                                        <p className="file-type">{fileType(file.name)}</p>
                                                        <p className={`file-name ${file.invalid ? 'file-error' : ''} `}>{file.name}</p>
                                                        <p className="file-size">({fileSize(file.size)})</p>
                                                        {file.invalid &&
                                                            <p className='file-error-message'>({errorMessage})</p>}
                                                    </div>
                                                    <button
                                                        className="file-remove"
                                                        onClick={() => removeFile(file.name)}>
                                                        <span>+</span>
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='item upload-input'>
                                    <ErrorMessage name='file'
                                                  className='error-field'
                                                  component='div'/>
                                    <SelectBox
                                        backgroundColor={'#F0F0F0'}
                                        label='Company Name'
                                        name='company'
                                        active={true}
                                        values={formProps.values.company}
                                        icon='role'
                                        onChange={formProps.setFieldValue}
                                        onBlur={formProps.setFieldTouched}
                                        withIcon={true}
                                        stat={selectOrganisation}
                                    />
                                    <ErrorMessage name='company'
                                                  className='error-field'
                                                  component='div'/>
                                </div>
                            </Form>
                        </div>
                    );
                }
            }
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    organisation: state.user.organisation,
});

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            assignDevices,
            getOrganisation,
        }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadCSVDevices);
