const roles = {
    SUPER_ADMIN: 'superadmin',
    ADMIN: 'admin',
    BILLING: 'billing'
};

const links = {
    BILLING_PAGE: '/billing',
    PLANS_PAGE: '/plans',
    SETTINGS_PAGE: '/settings',
    LIVE_ACTIONS: '/live-actions',
    DEVICES_PAGE: '/devices'
};

export {roles, links};