import { NotificationManager } from 'react-notifications';

import * as types from '../constants';
import { callApi } from '../utils/client';
import history from '../utils/history';


const storeUserData = (data: {token_data: string, user: object}, remember: boolean = false) => {
    const storage = localStorage;//remember ? localStorage : sessionStorage;

    storage.setItem('authToken', JSON.stringify(data.token_data));
    storage.setItem('userInfo', JSON.stringify(data.user));
};

/**
 *
 * @param field
 * @param remember
 * @returns {Function}
 */
export function login(field, remember) {
    return dispatch => {
        dispatch({
            type: types.FETCH_LOGIN,
        });

        return callApi('users/login', 'POST', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_LOGIN_SUCCESS,
                });
                // @ts-ignore
                storeUserData(json, remember);
                history.push('/');
            })
            .catch(error => {
                dispatch({
                    type: types.FETCH_LOGIN_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {Function}
 */
export function clearLoginError() {
    return dispatch => {
        dispatch({
            type: types.FETCH_CLEAN_LOGIN_ERROR,
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function signUpFirstForm(fields) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SIGN_UP_FIRST_STEP,
            payload: fields,
        });

        return callApi('users/valid-signup-first-form', 'POST', fields)
            .then(json => {
                dispatch({
                    type: types.FETCH_SIGN_UP_FIRST_STEP_SUCCESS,
                    payload: fields,
                });
            })
            .catch(error => {
                dispatch({
                    type: types.FETCH_SIGN_UP_FIRST_STEP_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {Function}
 */
export function signUp(fields) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SIGN_UP,
        });

        return callApi('users/signup', 'POST', fields)
            .then(json => {
                dispatch({
                    type: types.FETCH_SIGN_UP_SUCCESS,
                    payload: json,
                });
                // @ts-ignore
                if (json.token_data) {
                    // @ts-ignore
                    storeUserData(json);
                    history.push('/');
                } else {
                    const message = 'Verification email has been sent. Check your email!';

                    history.push({
                        pathname: '/login',
                        state: { message },
                    });

                    NotificationManager.info(message, undefined, 1000 * 60);
                }
            }).catch(error => {
                dispatch({
                    type: types.FETCH_SIGN_UP_FAIL,
                    payload: error.message,
                });
            }
        );
    };
}

/**
 *
 * @param email
 * @param url
 * @returns {Function}
 */
export function forgotPassword(email, url) {
    return dispatch => {
        dispatch({
            type: types.FETCH_FORGOT_PASSWORD,
        });

        callApi('users/reset-password', 'POST', { email, url })
            .then(json => {
                dispatch({
                    type: types.FETCH_FORGOT_PASSWORD_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_FORGOT_PASSWORD_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param password
 * @param token
 * @returns {Function}
 */
export function resetPassword(password, token, onSuccess) {
    return dispatch => {
        dispatch({
            type: types.FETCH_RESET_PASSWORD,
        });

        return callApi('users/set-password', 'PUT', { password, token })
            .then(json => {
                dispatch({
                    type: types.FETCH_RESET_PASSWORD_SUCCESS,
                    payload: json,
                });
                onSuccess?.();
            }).catch(error => {
            dispatch({
                type: types.FETCH_RESET_PASSWORD_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param token
 * @returns {Function}
 */
export function logOut(token) {
    return dispatch => {
        dispatch({
            type: types.FETCH_LOGOUT,
        });

        return callApi('users/logout', 'POST', { token })
            .then(json => {
                dispatch({
                    type: types.FETCH_LOGOUT_SUCCESS,
                    payload: json,
                });
                localStorage.clear();
                sessionStorage.clear();
                history.push('/login');
            }).catch(error => {
            dispatch({
                type: types.FETCH_LOGOUT_FAIL,
                payload: error.message,
            });
        });
    };
}

export const verification = (token) => dispatch => {
    dispatch({
        type: types.FETCH_VERIFICATION,
    });

    // @ts-ignore
    return callApi(`users/verify-email/${token}`, 'GET')
        .then(json => {
            dispatch({
                type: types.FETCH_VERIFICATION_SUCCESS,
                payload: json,
            });
            const message = 'Your email has been successfully verified!';

            history.push({
                pathname: '/login',
                state: {
                    message,
                },
            });
            dispatch({
                type: types.FETCH_OPEN_MODAL,
            });
        }).catch(error => {
            dispatch({
                type: types.FETCH_VERIFICATION_FAIL,
                payload: error.message,
            });

            NotificationManager.error(error.message);
            history.push('/login');
        });
};

export const createPassword = (token, data) => dispatch => {
    dispatch({
        type: types.FETCH_CREATE_PASSWORD,
    });

    return callApi(`users/create-password/${token}`, 'POST', data)
        .then(json => {
            dispatch({
                type: types.FETCH_CREATE_PASSWORD_SUCCESS,
                payload: json,
            });
            // @ts-ignore
            storeUserData(json);
            history.push('/');
            NotificationManager.info('Your password has been set!');
        }).catch(error => {
            dispatch({
                type: types.FETCH_CREATE_PASSWORD_FAIL,
                payload: error.message,
            });
        });
};

/**
 *
 */
export function refreshToken() {
    // const token = JSON.parse(sessionStorage.getItem('authToken')) || JSON.parse(localStorage.getItem('authToken'));
    //
    // localStorage.removeItem('authToken');
    // sessionStorage.removeItem('authToken');
    //
    // callApi('users/refresh-token', 'POST', { refresh_token:  token.refresh_token })
    //     .then(json => {
    //         sessionStorage.setItem('authToken', JSON.stringify(json));
    //         localStorage.setItem('authToken', JSON.stringify(json));
    //     }).catch(error => {});
}

/**
 *
 * @returns {Function}
 */
export function closeModal() {
    return dispatch => {
        dispatch({
            type: types.FETCH_CLOSE_MODAL,
        });
        console.log(dispatch);
    };
}