import * as types from '../constants';

export const initialState = {
    deviceLiveActions: [],
    loadingDeviceLiveAction: false,
    liveActions: {},
    loadingLiveActions: true,
    liveAction: {},
    rules: {},
    loadingRules: true,
    actions: [],
    loadingActions: true,
    loadingLiveAction: true,
    loadingUpdateLiveAction: false,
    loadingCreateLiveAction: false,
    loadingUpdateRuleAction: false,
    loadingSetRuleAction: false,
    error: false,
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function liveAction(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_GET_LIVE_ACTION_DEVICE:
            return {
                ...state,
                loadingDeviceLiveAction: false,
            };
        case types.FETCH_SET_RULE_ACTION:
            return {
                ...state,
                loadingSetRuleAction: true,
            };
        case types.FETCH_UPDATE_RULE_ACTION:
            return {
                ...state,
                loadingUpdateRuleAction: true,
            };
        case types.FETCH_GET_ALL_RULES:
            return {
                ...state,
                loadingRules: true,
            };
        case types.FETCH_GET_ALL_ACTIONS:
            return {
                ...state,
                loadingActions: true,
            };
        case types.FETCH_UPDATE_LIVE_ACTION:
            return {
                ...state,
                loadingUpdateLiveAction: true,
            };
        case types.FETCH_GET_LIVE_ACTION:
            return {
                ...state,
                loadingLiveAction: true,
            };
        case types.FETCH_SET_LIVE_ACTION:
            return {
                ...state,
                loadingCreateLiveAction: true,
            };
        case types.FETCH_GET_ALL_RULES_SUCCESS:
            return {
                ...state,
                loadingRules: false,
                rules: action.payload,
                loadingActions: false,
            };
        case types.FETCH_SET_RULE_ACTION_SUCCESS:
            return {
                ...state,
                liveAction: {
                    ...state.liveAction,
                    rules: [ ...state.liveAction.rules, action.payload ],
                },
                loadingSetRuleAction: false,
            };
        case types.FETCH_REMOVE_RULE_ACTION_SUCCESS:
            return {
                ...state,
                liveAction: {
                    ...state.liveAction,
                    rules: action.payload.records,
                },
            };
        case types.FETCH_UPDATE_RULE_ACTION_SUCCESS:
            return {
                ...state,
                loadingUpdateRuleAction: false,
            };
        case types.FETCH_GET_ALL_ACTIONS_SUCCESS:
            return {
                ...state,
                actions: action.payload,
                loadingRules: false,
            };
        case types.FETCH_GET_LIVE_ACTION_DEVICE_SUCCESS:
            return {
                ...state,
                deviceLiveActions: action.payload,
                loadingDeviceLiveAction: true,
            };
        case types.FETCH_UPDATE_LIVE_ACTION_SUCCESS:
            return {
                ...state,
                liveAction: action.payload,
                loadingUpdateLiveAction: false,
            };
        case types.FETCH_GET_LIVE_ACTION_SUCCESS:
            return {
                ...state,
                liveAction: action.payload,
                loadingLiveAction: false,
            };
        case types.FETCH_SET_LIVE_ACTION_SUCCESS:
            return {
                ...state,
                loadingCreateLiveAction: false,
            };
        case types.FETCH_GET_ALL_RULES_FAIL:
            return {
                ...state,
                loadingRules: false,
                error: true,
            };
        case types.FETCH_GET_ALL_ACTIONS_FAIL:
            return {
                ...state,
                loadingActions: false,
                error: true,
            };
        case types.FETCH_SET_RULE_ACTION_FAIL:
            return {
                ...state,
                loadingSetRuleAction: false,
                error: true,
            };
        case types.FETCH_GET_LIVE_ACTION_DEVICE_FAIL:
            return {
                ...state,
                loadingDeviceLiveAction: false,
                error: true,
            };

        case types.FETCH_GET_ALL_LIVE_ACTIONS:
        case types.FETCH_REMOVE_LIVE_ACTION:
        case types.FETCH_REMOVE_LIVE_ACTIONS:
            return {
                ...state,
                loadingLiveActions: true,
            };
        case types.FETCH_GET_ALL_LIVE_ACTIONS_SUCCESS:
        case types.FETCH_REMOVE_LIVE_ACTION_SUCCESS:
        case types.FETCH_REMOVE_LIVE_ACTIONS_SUCCESS:
            return {
                ...state,
                liveActions: action.payload,
                loadingLiveActions: false,
            };
        case types.FETCH_GET_ALL_LIVE_ACTIONS_FAIL:
        case types.FETCH_REMOVE_LIVE_ACTION_FAIL:
        case types.FETCH_REMOVE_LIVE_ACTIONS_FAIL:
            return {
                ...state,
                loadingLiveActions: false,
                error: true,
            };
        case types.FETCH_GET_LIVE_ACTION_FAIL:
            return {
                ...state,
                loadingLiveAction: false,
                error: true,
            };
        case types.FETCH_UPDATE_RULE_ACTION_FAIL:
            return {
                ...state,
                loadingUpdateRuleAction: false,
                error: true,
            };
        case types.FETCH_UPDATE_LIVE_ACTION_FAIL:
            return {
                ...state,
                loadingUpdateLiveAction: false,
                error: true,
            };
        case types.FETCH_SET_LIVE_ACTION_FAIL:
            return {
                ...state,
                loadingCreateLiveAction: false,
                error: true,
            };
        default:
            return state;
    }
};
