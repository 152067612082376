import React, {useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';

import Breadcrumbs from '../../../ui/breadcrumbs';
import PageContainer from '../../../ui/pageContainer';
import FormLiveAction from '../formLiveAction';

import {setLiveAction} from '../../../../actions/liveAction';

import './styles.scss';

const CreateLiveAction = ({actions: {setLiveAction}, loading}) => {
    const [detectActionForm, setDetectActionForm] = useState(false);

    return (

        <div className="live-actions-edit">
            <div className="live-actions-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <div className='live-actions-container'>
                    <FormLiveAction
                        action={setLiveAction}
                        loading={loading}
                        setDetectActionForm={setDetectActionForm}
                        detectActionForm={detectActionForm}
                    />
                </div>
            </PageContainer>
        </div>


    );
};

CreateLiveAction.propTypes = {
    actions: PropTypes.shape({
        setLiveAction: PropTypes.func,
    }),
    loading: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{loading: boolean}}
 */
function mapStateToProps(state) {
    return {
        loading: state.liveAction.loadingCreateLiveAction,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({setLiveAction: setLiveAction}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setLiveAction,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLiveAction);
