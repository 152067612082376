import React from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../ui/loader';
import InputBox from '../../../ui/inputBox';
import AuthLayout from '../authLayout';
import { createPassword } from '../../../../actions/auth';

const CreatePassword = () => {
    const dispatch = useDispatch();
    const { verificationToken } = useParams<{ verificationToken: string }>();

    // @ts-ignore
    const loading: boolean = useSelector(state => state.auth.createPasswordLoading);

    return (
        <AuthLayout>
            <Formik
                initialValues={{
                    password: '',
                    password_repeat: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        password: Yup.string().required('Password is required').matches(
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                        ),
                        password_repeat: Yup.string()
                            .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
                            .required('Password confirm is required'),
                    })
                }
                onSubmit={fields => {
                    debugger
                    dispatch(createPassword(verificationToken, fields));
                }}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className='form-box'>
                            <div className='title'>Welcome to Mobile Asset Solutions</div>
                            <div className='description'>Set password</div>
                            <div className='input-box'>
                                <InputBox
                                    className
                                    label='Password'
                                    icon='lock'
                                    name='password'
                                    type='password'
                                    setFieldValue={setFieldValue}
                                />
                                <ErrorMessage name='password' className='error-field' component='div' />
                            </div>
                            <div className='input-box'>
                                <InputBox
                                    className
                                    label='Confirm password'
                                    icon='lock'
                                    name='password_repeat'
                                    type='password'
                                    setFieldValue={setFieldValue}
                                />
                                <ErrorMessage name='password_repeat' className='error-field' component='div' />
                            </div>
                            <div className='btn-link'>
                                {loading && (
                                    <div className='loading-square'>
                                        <div className='box'>
                                            <Loader />
                                        </div>
                                    </div>
                                )}
                                <button type='submit'>send</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};


export default CreatePassword;
