import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import './styles.scss';

const Loader = ({ orange, width, height }) => {
    return (
        <div className={classNames({ loader: true, orange })} style={{ width, height }} />
    );
};

Loader.defaultProps = {
    orange: false,
    width: 30,
    height: 30,
};

Loader.propTypes = {
    orange: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default Loader;
