const authHeaders = () => {
    return (localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) ? {
        Authorization: localStorage.getItem('authToken')
            ?
            (`${JSON.parse(localStorage.getItem('authToken')).token_type} ${JSON.parse(localStorage.getItem('authToken')).access_token}`)
            :
            (`${JSON.parse(sessionStorage.getItem('authToken')).token_type} ${JSON.parse(sessionStorage.getItem('authToken')).access_token}`),
    } : {};
}

export default authHeaders;