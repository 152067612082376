import * as types from '../constants';

export const initialState = {
    invoice: {},
    error: false,
    invoiceLoading: true,
    statisticLoading: true,
    cardLoading: true,
    cards: [],
    creditCard: [],
    statistic: {},
    pdf: '',
    token: '',
    plaidToken: '',
    loadingCards: false,
    creditLog: {},
    loadingCreditLog: true,
    loadingWallet: false,
    successAddWallet: '',
    walletLoaded: false
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function billing(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_ADD_CARD:
            return {
                ...state,
                cardLoading: true,
            };
        case types.FETCH_GET_CARDS:
            return {
                ...state,
                loadingCards: true,
            };
        case types.FETCH_INVOICE:
        case types.FETCH_PDF:
            return {
                ...state,
                invoiceLoading: true,
            };
        case types.FETCH_STATISTIC:
            return {
                ...state,
                statisticLoading: true,
            };
        case types.FETCH_GET_CARDS_SUCCESS:
            return {
                ...state,
                cards: action.payload.filter(el => el.processor === 'Braintree'),
                creditCard: action.payload.filter(el => el.processor === 'Stripe'),
                loadingCards: false,
            };
        case types.FETCH_PDF_SUCCESS:
            return {
                ...state,
                pdf: action.payload,
                invoiceLoading: false,
            };

        case types.FETCH_GET_WALLET:
            return {
                ...state,
                loadingWallet: true
            }
        case types.FETCH_GET_WALLET_SUCCESS:
            return {
                ...state,
                wallet: action.payload,
                loadingWallet: false,
                walletLoaded: true
            }
        case types.FETCH_GET_WALLET_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingWallet: false,
                walletLoaded: false
            }
        case types.FETCH_UPDATE_WALLET:
            return {
                ...state,
                loadingWallet: true
            }
        case types.FETCH_UPDATE_WALLET_SUCCESS:
            return {
                ...state,
                wallet: action.payload,
                loadingWallet: false,
                walletLoaded: true
            }
        case types.FETCH_UPDATE_WALLET_FAIL:
            return {
                ...state,
                error: action.payload,
                walletLoaded: false
            }
        case types.FETCH_CREATE_WALLET:
            return {
                ...state,
                payload: action.payload
            }
        case types.FETCH_CREATE_WALLET_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                walletLoaded: true
            }
        case types.FETCH_CREATE_WALLET_FAIL:
            return {
                ...state,
                error: action.payload,
                walletLoaded: false
            }
        case types.FETCH_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
                invoiceLoading: false,
            };
        case types.FETCH_GET_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.payload,
            };
        case types.FETCH_GET_PLAID_TOKEN_SUCCESS:
            return {
                ...state,
                plaidToken: action.payload
            };
        case types.FETCH_ADD_CARD_SUCCESS:
            return {
                ...state,
                cardLoading: false,
                cards: [action.payload],
            };
        case types.FETCH_ADD_ACH_SUCCESS:
            return {
                ...state,
                creditCard: action.payload.filter(card => card.processor === 'Stripe'),
            };
        case types.FETCH_REMOVE_ACH_SUCCESS:
            return {
                ...state,
                creditCard: [],
            };
        case types.FETCH_STATISTIC_SUCCESS:
            return {
                ...state,
                statistic: action.payload,
                statisticLoading: false,
            };
        case types.FETCH_STATISTIC_FAIL:
            return {
                ...state,
                statisticLoading: false,
                error: true,
            };
        case types.FETCH_PDF_FAIL:
            return {
                ...state,
                invoiceLoading: false,
                error: true,
            };
        case types.FETCH_GET_CARDS_FAIL:
            return {
                ...state,
                error: true,
                loadingCards: false,
            };
        case types.FETCH_INVOICE_FAIL:
            return {
                ...state,
                invoiceLoading: false,
                error: true,
            };
        case types.FETCH_ADD_CARD_FAIL:
        case types.FETCH_GET_TOKEN_FAIL:
            return {
                ...state,
                cardLoading: false,
                error: true,
            };
        case types.FETCH_GET_PLAID_TOKEN_FAIL:
            return {
                ...state,
                error: true
            }
        case types.FETCH_GET_CREDIT_LOG:
            return {
                ...state,
                loadingCreditLog: true,
            };
        case types.FETCH_GET_CREDIT_LOG_SUCCESS:
            return {
                ...state,
                creditLog: action.payload,
                loadingCreditLog: false,
            };
        case types.FETCH_GET_CREDIT_LOG_FAIL:
            return {
                ...state,
                loadingCreditLog: false,
                error: true,
            };
        case types.FETCH_SUCCESS_CREATE_WALLET:
            return {
                ...state,
                successAddWallet: action.payload.data
            }
        case types.FETCH_GET_RECOMMENDED_PAYMENT:
        case types.FETCH_GET_RECOMMENDED_PAYMENT_SUCCESS:
            return {
                ...state,
                recommendedPayment: action.payload
            }
        case types.FETCH_GET_RECOMMENDED_PAYMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
};
