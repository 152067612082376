export const FETCH_UPDATE_BILLING_CHECKBOX = 'FETCH_UPDATE_BILLING_CHECKBOX';
export const FETCH_UPDATE_BILLING_CHECKBOX_SUCCESS = 'FETCH_UPDATE_BILLING_CHECKBOX_SUCCESS';
export const FETCH_UPDATE_BILLING_CHECKBOX_FAIL = 'FETCH_UPDATE_BILLING_CHECKBOX_FAIL';

export const FETCH_CREATE_BILLING_ADDRESS = 'FETCH_CREATE_BILLING_ADDRESS';
export const FETCH_CREATE_BILLING_ADDRESS_SUCCESS = 'FETCH_CREATE_BILLING_ADDRESS_SUCCESS';
export const FETCH_CREATE_BILLING_ADDRESS_FAIL = 'FETCH_CREATE_BILLING_ADDRESS_FAIL';

export const FETCH_DELETE_ADDRESS = 'FETCH_DELETE_ADDRESS';
export const FETCH_DELETE_ADDRESS_SUCCESS = 'FETCH_DELETE_ADDRESS_SUCCESS';
export const FETCH_DELETE_ADDRESS_FAIL = 'FETCH_DELETE_ADDRESS_FAIL';

export const FETCH_CHANGE_PASSWORD = 'FETCH_CHANGE_PASSWORD';
export const FETCH_CHANGE_PASSWORD_SUCCESS = 'FETCH_CHANGE_PASSWORD_SUCCESS';
export const FETCH_CHANGE_PASSWORD_FAIL = 'FETCH_CHANGE_PASSWORD_FAIL';

export const FETCH_API_ACCESS = 'FETCH_API_ACCESS';
export const FETCH_API_ACCESS_SUCCESS = 'FETCH_API_ACCESS_SUCCESS';
export const FETCH_API_ACCESS_FAIL = 'FETCH_API_ACCESS_FAIL';

export const FETCH_REMOVE_API_ACCESS = 'FETCH_REMOVE_API_ACCESS';
export const FETCH_REMOVE_API_ACCESS_SUCCESS = 'FETCH_REMOVE_API_ACCESS_SUCCESS';
export const FETCH_REMOVE_API_ACCESS_FAIL = 'FETCH_REMOVE_API_ACCESS_FAIL';

export const FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT = 'FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT';
export const FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT_SUCCESS = 'FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT_SUCCESS';
export const FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT_FAIL = 'FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT_SUCCESS';

export const FETCH_BILLING_ADDRESS = 'FETCH_BILLING_ADDRESS';
export const FETCH_BILLING_ADDRESS_SUCCESS = 'FETCH_BILLING_ADDRESS_SUCCESS';
export const FETCH_BILLING_ADDRESS_FAIL = 'FETCH_BILLING_ADDRESS_FAIL';

export const FETCH_UPDATE_ADDRESS = 'FETCH_UPDATE_ADDRESS';
export const FETCH_UPDATE_ADDRESS_SUCCESS = 'FETCH_UPDATE_ADDRESS_SUCCESS';
export const FETCH_UPDATE_ADDRESS_FAIL = 'FETCH_UPDATE_ADDRESS_FAIL';

export const CLEAR_BILLING_ADDRESS = "CLEAR_BILLING_ADDRESS";