import React from 'react';
import * as PropTypes from 'prop-types';

import './styles.scss';

const PageContainer = ({ children }) => {
    return(
        <div className='page-container'>{children}</div>
    );
};

PageContainer.defoultProps = {
    children: PropTypes.any,
};

PageContainer.propTypes = {
    children: PropTypes.any,
};

export default PageContainer;
