import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';

import PageContainer from '../../ui/pageContainer';
import Breadcrumbs from '../../ui/breadcrumbs';
import Plan from '../../ui/plan';
import Loader from '../../ui/loader';
import CustomPlan from '../../ui/modals/customPlan';
import Warn from '../../ui/modals/warn';

import { customPlan, getPlans, resetCustomPlan } from '../../../actions/plans';
import { changePlanDevice } from '../../../actions/devices';

import { splitUrl } from '../../../utils/splitUrl';

import './styles.scss';

const Plans = ({ actions: { getPlans, customPlan, resetCustomPlan, changePlanDevice }, loading, plans, sendFormSuccess, formLoading, history: { location } }) => {

    useEffect(() => {
        getPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [isOpenCustomPlan, setIsOpenCustomPlan] = useState(false);
    const [warn, setWarn] = useState(false);

    const successCallback = data => {
        if(data.length > 0) {
            setWarn(true);
        }
    };

    return (
        <div className='plans'>
            {isOpenCustomPlan && (
                <CustomPlan
                    customPlan={customPlan}
                    closeModal={setIsOpenCustomPlan}
                    formLoading={formLoading}
                    sendFormSuccess={sendFormSuccess}
                    resetCustomPlan={resetCustomPlan}
                />
            )}

            {warn && (
                <Warn
                    closeModal={setWarn}
                    text='Your selected devices have changed plan.'
                />
            )}

            <div className="plans-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <div className='plan-container'>
                    {(loading || !plans.records.length > 0) ? (
                        <div className='loading-box'><Loader orange /></div>
                    ) : (
                        <>
                            <div className='plan-box'>
                                <div className='btn-title-box'>
                                    <div className='title'>Current plan:</div>
                                </div>
                                <div className='plans-standard'>
                                    <Plan planInfo={plans.records[1]}/>
                                    <div className='request-plan' onClick={() => setIsOpenCustomPlan(true)}>
                                        request for custom plan
                                    </div>
                                </div>
                            </div>
                            <div className='plan-box'>
                                <div className='title second-block'>Available plans:</div>
                                <div className='text-before-plans'>By changing the plan you will change the assigned plan for all selected units. This change is immediate and will impact the current billing cycle.</div>
                                <div className='plans-box'>
                                    {plans.records.map((plan, index) => (
                                        <Plan
                                            key={index}
                                            black={true}
                                            openSlide={index === 0}
                                            planInfo={plan}
                                            changePlan={changePlanDevice}
                                            deviceIds={splitUrl(location.search).deviceIds}
                                            successCallback={successCallback}
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </PageContainer>
        </div>
    );
};

Plans.propTypes = {
    actions: PropTypes.shape({
        getPlans: PropTypes.func,
        customPlan: PropTypes.func,
        resetCustomPlan: PropTypes.func,
        changePlanDevice: PropTypes.func,
    }),
    loading: PropTypes.bool,
    formLoading: PropTypes.bool,
    plans: PropTypes.object,
    sendFormSuccess: PropTypes.string,
};

/**
 *
 * @param state
 * @returns {{formLoading: Requireable<boolean> | boolean, plans: Requireable<any[]> | plans | Array | *, loading: *, sendFormSuccess: Requireable<string> | null}}
 */
function mapStateToProps(state) {
    return {
        plans: state.plans.plans,
        loading: state.plans.loading,
        sendFormSuccess: state.plans.sendFormSuccess,
        formLoading:state.plans.formLoading,
        devicePlanInfo:state.devices.device,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({changePlanDevice: changePlanDevice, resetCustomPlan: resetCustomPlan, customPlan: customPlan, getPlans: getPlans}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getPlans,
            customPlan,
            resetCustomPlan,
            changePlanDevice,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
