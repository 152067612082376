export const FETCH_GET_TOKEN = 'FETCH_GET_TOKEN';
export const FETCH_GET_TOKEN_SUCCESS = 'FETCH_GET_TOKEN_SUCCESS';
export const FETCH_GET_TOKEN_FAIL = 'FETCH_GET_TOKEN_FAIL';

export const FETCH_ADD_CARD = 'FETCH_ADD_CARD';
export const FETCH_ADD_CARD_SUCCESS = 'FETCH_ADD_CARD_SUCCESS';
export const FETCH_ADD_CARD_FAIL = 'FETCH_ADD_CARD_FAIL';

export const FETCH_PDF = 'FETCH_PDF';
export const FETCH_PDF_SUCCESS = 'FETCH_PDF_SUCCESS';
export const FETCH_PDF_FAIL = 'FETCH_PDF_FAIL';

export const FETCH_INVOICE = 'FETCH_INVOICE';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAIL = 'FETCH_INVOICE_FAIL';

export const FETCH_STATISTIC = 'FETCH_STATISTIC';
export const FETCH_STATISTIC_SUCCESS = 'FETCH_STATISTIC_SUCCESS';
export const FETCH_STATISTIC_FAIL = 'FETCH_STATISTIC_FAIL';

export const FETCH_GET_CARDS = 'FETCH_GET_CARDS';
export const FETCH_GET_CARDS_SUCCESS = 'FETCH_GET_CARDS_SUCCESS';
export const FETCH_GET_CARDS_FAIL = 'FETCH_GET_CARDS_FAIL';

export const FETCH_REMOVE_CARD = 'FETCH_REMOVE_CARD';
export const FETCH_REMOVE_CARD_SUCCESS = 'FETCH_REMOVE_CARD_SUCCESS';
export const FETCH_REMOVE_CARD_FAIL = 'FETCH_REMOVE_CARD_FAIL';

export const FETCH_ADD_ACH = 'FETCH_ADD_ACH';
export const FETCH_ADD_ACH_SUCCESS = 'FETCH_ADD_ACH_SUCCESS';
export const FETCH_ADD_ACH_FAIL = 'FETCH_ADD_ACH_FAIL';

export const FETCH_REMOVE_ACH = 'FETCH_REMOVE_ACH';
export const FETCH_REMOVE_ACH_SUCCESS = 'FETCH_REMOVE_ACH_SUCCESS';
export const FETCH_REMOVE_ACH_FAIL = 'FETCH_REMOVE_ACH_FAIL';

export const FETCH_GET_CREDIT_LOG_SUCCESS = 'FETCH_GET_CREDIT_LOG_SUCCESS';
export const FETCH_GET_CREDIT_LOG = 'FETCH_GET_CREDIT_LOG';
export const FETCH_GET_CREDIT_LOG_FAIL = 'FETCH_GET_CREDIT_LOG_FAIL';

export const FETCH_GET_PLAID_TOKEN = 'FETCH_GET_PLAID_TOKEN';
export const FETCH_GET_PLAID_TOKEN_SUCCESS = 'FETCH_GET_PLAID_TOKEN_SUCCESS';
export const FETCH_GET_PLAID_TOKEN_FAIL = 'FETCH_GET_PLAID_TOKEN_FAIL';

export const FETCH_GET_WALLET = 'FETCH_GET_WALLET';
export const FETCH_GET_WALLET_SUCCESS = 'FETCH_GET_WALLET_SUCCESS';
export const FETCH_GET_WALLET_FAIL = 'FETCH_GET_WALLET_FAIL';


export const FETCH_CREATE_WALLET = 'FETCH_CREATE_WALLET';
export const FETCH_CREATE_WALLET_SUCCESS = 'FETCH_CREATE_WALLET_SUCCESS';
export const FETCH_CREATE_WALLET_FAIL = 'FETCH_CREATE_WALLET_FAIL';

export const FETCH_UPDATE_WALLET = 'FETCH_UPDATE_WALLET';
export const FETCH_UPDATE_WALLET_SUCCESS = 'FETCH_UPDATE_WALLET_SUCCESS';
export const FETCH_UPDATE_WALLET_FAIL = 'FETCH_UPDATE_WALLET_FAIL';

export const FETCH_SUCCESS_CREATE_WALLET = 'FETCH_SUCCESS_CREATE_WALLET';

export const FETCH_GET_RECOMMENDED_PAYMENT = 'FETCH_GET_RECOMMENDED_PAYMENT';
export const FETCH_GET_RECOMMENDED_PAYMENT_SUCCESS = 'FETCH_GET_RECOMMENDED_PAYMENT_SUCCESS';
export const FETCH_GET_RECOMMENDED_PAYMENT_FAIL = 'FETCH_GET_RECOMMENDED_PAYMENT_FAIL';