import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import PageContainer from '../../ui/pageContainer';
import Breadcrumbs from '../../ui/breadcrumbs';
import Pagination from '../../ui/pagination';
import Loader from '../../ui/loader';
import RemoveItem from '../../ui/modals/removeItem';

import { getAllLiveActions, removeLiveAction, removeLiveActions } from '../../../actions/liveAction';

import { renderIcon } from '../../../utils/renderIcon';
import { useWindowWidth } from '../../../utils/useWindowWidth';
import history from '../../../utils/history';

import { getCountLines, heightLineTrLiveActionTable } from '../../../constants/app';

import './style.scss';

const LiveActions = ({
    actions: {
        getAllLiveActions,
        removeLiveAction,
        removeLiveActions,
    },
    liveActions : {
        records,
        total,
    },
    loading,
}) => {
    const table = useRef(null);
    const tableHeader = useRef(null);
    const scrolledElem = useRef(null);
    const [currentId, setCurrentId] = useState(null);
    const [removeModal, setRemoveModal] = useState(false);
    const [removeModalCheckbox, setRemoveModalCheckbox] = useState(false);
    const [currentPagination, setCurrentPagination] = useState(1);
    const [countLines, setCountLines] = useState(0);
    const [localLiveActions, setLocalLiveActions] = useState(records || []);
    const [fixedElem, setFixedElem] = useState(false);
    const handleScrollTable = (event) => event.target.scrollLeft > 0 ? setFixedElem(true) : setFixedElem(false);

    const hello = '123';


    useEffect(() => {
        const element = scrolledElem.current;

        element.addEventListener('scroll', handleScrollTable);

        return () => element.removeEventListener('scroll', handleScrollTable);
    },[]);

    const { width: windowWidth } = useWindowWidth();

    useEffect(() => {
        setLocalLiveActions([]);
        setTimeout(() => {
            setCountLines(
                getCountLines(
                    table.current.clientHeight,
                    tableHeader.current.clientHeight,
                    heightLineTrLiveActionTable,
                )
            );

            getAllLiveActions(
                getCountLines(
                    table.current.clientHeight,
                    tableHeader.current.clientHeight,
                    heightLineTrLiveActionTable
                ),
                currentPagination
            );
        },0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination, windowWidth]);

    useEffect(() => {
        setLocalLiveActions(records);
        setLiveActionsCheckbox([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records]);

    const handleGetStatus = (status) => {
        switch (status) {
            case 1:
                return 'Active';
            case 0:
                return 'Disable';
            default:
                return '-';
        }
    };

    const [allChecked, setAllChecked] = useState(false);
    const [liveActionsCheckbox, setLiveActionsCheckbox] = useState([]);
    const handleCheckAll = () => {
        setAllChecked(!allChecked);
        setLiveActionsCheckbox(allChecked ? [] : records.map(item => item.id));
    };

    const handleChange = id => {
        setAllChecked();
        const newDevices = liveActionsCheckbox.indexOf(id) !== -1
            ? liveActionsCheckbox.filter(item => item !== id)
            : [...liveActionsCheckbox, id];

        setLiveActionsCheckbox(newDevices);
    };


return (
        <div className='live-actions'>
            {removeModal &&
                <RemoveItem
                    closeModal={setRemoveModal}
                    id={currentId}
                    remove={removeLiveAction}
                    deleteUser
                    size={countLines}
                    page={currentPagination}
                />
            }

            {removeModalCheckbox &&
                <RemoveItem
                    closeModal={setRemoveModalCheckbox}
                    id={liveActionsCheckbox}
                    remove={removeLiveActions}
                    deleteUser
                    size={countLines}
                    page={currentPagination}
                />
            }
            <div className="live-actions-header">
                <div className="header-container">
                    <Breadcrumbs/>
                    <div className='btn-box'>
                        <div className={classNames({ btn : true, 'first-btn': true, disable: liveActionsCheckbox.length === 0 })}
                             onClick={() => liveActionsCheckbox.length !== 0 ? setRemoveModalCheckbox(true) : null }>
                            remove selected
                        </div>
                        <div className='btn' onClick={() => history.push('/live-actions/new')}>add new</div>
                    </div>
                </div>
            </div>
            <PageContainer>
                <div className='live-actions-container'>
                    {loading && (
                        <div className='load-box'>
                            <Loader orange/>
                        </div>
                    )}
                    {/*<div><Breadcrumbs /></div>*/}
                    {localLiveActions && localLiveActions.length === 0 && (
                        <div className='empty-live-action'>There are no live actions. For add live action click button "add new"</div>
                    )}

                    <div className='under-element' ref={table}>
                        <div className={classNames({
                            'mobile-checkbox-all': true,
                            'shadow-box': fixedElem,
                        })}>
                            <div className='checkbox-box'>
                                <input
                                    onChange={handleCheckAll}
                                    className='styled-checkbox'
                                    id='checkbox-all-mobile'
                                    type='checkbox'
                                    checked={allChecked}
                                />
                                <label htmlFor='checkbox-all-mobile' />
                            </div>
                        </div>
                        <div className={classNames({
                            'mobile-checkbox': true,
                            'shadow-box': fixedElem,
                        })}>
                            {localLiveActions && localLiveActions.map((device, index) => {
                                return (
                                    <div className='checkbox-box' key={index}>
                                        <input
                                            onChange={() => handleChange(device.id)}
                                            className='styled-checkbox'
                                            id={`mobile-checkbox-${device.id}`}
                                            type='checkbox'
                                            checked={liveActionsCheckbox.find(item => item === device.id)}
                                        />
                                        <label htmlFor={`mobile-checkbox-${device.id}`} />
                                    </div>
                                );
                            })}
                        </div>
                        <div className='live-table' ref={scrolledElem}>
                            <table>
                                <thead ref={tableHeader}>
                                <tr>
                                    {localLiveActions && localLiveActions.length !== 0 && (
                                        <>
                                        <th className='th-checkbox'>
                                            <input
                                                onChange={handleCheckAll}
                                                className='styled-checkbox'
                                                id='styled-checkbox'
                                                type='checkbox'
                                                checked={allChecked}
                                            />
                                            <label htmlFor='styled-checkbox' />
                                        </th>
                                        <th>Name</th>
                                        <th>Rule</th>
                                        <th>Action</th>
                                        <th>Status</th>
                                        <th>Type</th>
                                        <th className='action'>Actions</th>
                                        </>
                                    )}
                                </tr>
                                </thead>
                                <tbody>
                                {localLiveActions && localLiveActions.map((item, index) => {
                                    const localRule = [];

                                    item.rules.forEach(elem => {
                                        if(localRule.indexOf(elem.rule.name) === -1) {
                                            localRule.push(elem.rule.name);
                                        }
                                    });

                                    const localAction = [];

                                    item.rules.forEach(elem => {
                                        elem.actions.forEach(el => {
                                            if(localAction.indexOf(el.name) === -1) {
                                                localAction.push(el.name);
                                            }
                                        });
                                    });

                                   return (
                                       <tr key={index}>
                                           <td className='td-checkbox'>
                                               <input
                                                   onChange={() => handleChange(item.id)}
                                                   className='styled-checkbox'
                                                   id={`checkbox-${item.id}`}
                                                   type='checkbox'
                                                   checked={liveActionsCheckbox.find(el => el === item.id)}
                                               />
                                               <label htmlFor={`checkbox-${item.id}`} />
                                           </td>
                                           <td>{item.name}</td>
                                           <td className='one-line'>
                                               {localRule.map((item, i) => {
                                                   return (
                                                       <span key={`${item}_${i}`}>{item}{localRule.length - 1 !== i ? ', ' : null}</span>
                                                   );
                                               })}
                                           </td>
                                           <td className='one-line'>
                                               {localAction.map((elem,j) => {
                                                   return (
                                                       <span key={`${elem}_${j}`}>{elem}{localAction.length - 1 !== j ? ', ' : null}</span>
                                                   );
                                               })}
                                           </td>
                                           <td className={classNames(handleGetStatus(item.status))}>
                                               {handleGetStatus(item.status)}
                                           </td>
                                           <td>{item.type ? 'Global' : 'Per Unit'}</td>
                                           <td>
                                               <div className='icons'>
                                                   <div className='js-update-btn' data-tip='Update' onClick={() => history.push(`/live-actions/${item.id}`)}>
                                                       {renderIcon('pencil')}
                                                   </div>
                                                   <div className='js-delete-btn' data-tip='Delete' onClick={() => { setRemoveModal(true); setCurrentId(item.id); }}>
                                                       {renderIcon('trash')}
                                                   </div>
                                                   <ReactTooltip backgroundColor='#222222' arrowColor='#222222' />
                                               </div>
                                           </td>
                                       </tr>
                                   );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='pagination-box'>
                        <Pagination
                            handleClick={setCurrentPagination}
                            total={total ? Math.ceil(parseInt(total) / countLines) : 0}
                            current={currentPagination}
                        />
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

LiveActions.propTypes = {
    actions: PropTypes.shape({
        getAllLiveActions: PropTypes.func,
        removeLiveAction: PropTypes.func,
        removeLiveActions: PropTypes.func,
    }),
    loading: PropTypes.bool,
    liveActions: PropTypes.shape({
        actions: PropTypes.array,
        total: PropTypes.number,
    }),
};

/**
 *
 * @param state
 * @returns {{liveActions: Requireable<InferProps<{total: Requireable<number>, actions: Requireable<any[]>}>> | initialState.liveActions | {}, loading: boolean}}
 */
function mapStateToProps(state) {
    return {
        liveActions: state.liveAction.liveActions,
        loading: state.liveAction.loadingLiveActions,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({removeLiveAction: removeLiveAction, getAllLiveActions: getAllLiveActions, removeLiveActions: removeLiveActions}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getAllLiveActions,
            removeLiveAction,
            removeLiveActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveActions);
