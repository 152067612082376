/* eslint-disable require-jsdoc */
import React, { useEffect, useRef, useState } from 'react';
import InputBox from '../../inputBox';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from '../../modal';
import classNames from 'classnames';
import './styles.scss';
import SelectBox from '../../selectBox';

function useOutsideAlerter(ref, close) {
    const reactSelectClassnames = [' css-1tvj958-option', ' css-1bdfte4-option'];

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !reactSelectClassnames.includes(event.target.className)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener('click', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    },);
}

const WalletSettings = ({
    closeModal,
    wallet,
    setShowWalletBalance,
    showWalletBalance,
    cards,
    creditCard,
    updateWallet,
    successAddWallet,
    addWalletSuccess,
    createWallet,
    recommendedPayment }) => {
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);
    const paymentMethods = [];

    creditCard && paymentMethods.push(creditCard);
    cards && paymentMethods.push(cards);

    const closeModalOnUpdate = () => {
        close();
    };


    const AutoRechargeSchema = Yup.object().shape({
        auto_recharge: Yup.number()
            .min(recommendedPayment, `Auto recharge must be greater than or equal to ${recommendedPayment}$`)
            .label('Auto recharge'),
        minimum_balance: Yup.number()
            .min(recommendedPayment, `Minimum balance must be greater than or equal to ${recommendedPayment}$`)
            .label('Minimum balance'),
    });


    useEffect(() => {
        if(successAddWallet) {
            addWalletSuccess();
            setTimeout(() => {
                addWalletSuccess();
                close();
            }, 3000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successAddWallet]);


    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        },300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'wallet-settings-modal': true, open: flag })} ref={wrapperRef}>
                <div className='title'>Wallet</div>
                <div className='form-container'>
                    <Formik
                        initialValues={{
                            amount: wallet ? String(wallet.auto_recharge) : recommendedPayment,
                            auto_recharge: wallet ? String(wallet.auto_recharge) : recommendedPayment,
                            minimum_balance: wallet ? String(wallet.minimum_balance) : recommendedPayment,
                            payment_method_id : paymentMethods.length > 0
                                ? {
                                    value: wallet?.payment_method_id,
                                    label: paymentMethods.find(item => item?.id === String(wallet?.payment_method_id))?.type,
                                }
                                : '',
                        }}

                        validationSchema={AutoRechargeSchema}

                        onSubmit={(values) => {
                            const field = { ...values, payment_method_id: values.payment_method_id.value };

                            // wallet && delete field.amount;
                            delete field.payment_methods;

                                if (wallet && Object.keys(wallet).length > 0) {
                                    updateWallet(field, closeModalOnUpdate);
                                    setShowWalletBalance(!showWalletBalance);
                                } else {
                                    createWallet(field, closeModalOnUpdate);
                                }
                            }

                        }

                    >
                        {({
                              values,
                              setFieldValue,
                              setFieldTouched,
                                errors,
                          }) => (
                            <Form>
                                <div className='contact-info-box'>
                                    {wallet
                                        ? <div>
                                            <div className='form-input'>
                                                <InputBox
                                                    label='Auto recharge'
                                                    name='auto_recharge'
                                                    type='text'
                                                    icon='moneyAmount'
                                                    setFieldValue={setFieldValue}
                                                    value={values.auto_recharge}
                                                    className={errors.auto_recharge && 'input-error'}
                                                    maskDigit='true'
                                                />
                                                <div className='helper-text'>{errors.auto_recharge}</div>
                                            </div>
                                            <div className='form-input'>
                                                <InputBox
                                                    label='Min balance'
                                                    name='minimum_balance'
                                                    icon='moneyAmount'
                                                    type='text'
                                                    setFieldValue={setFieldValue}
                                                    value={values.minimum_balance}
                                                    className={errors.minimum_balance && 'input-error'}
                                                    maskDigit='true'
                                                />
                                                <div className='helper-text'>{errors.minimum_balance}</div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className='form-input'>
                                                <InputBox
                                                    label='Amount'
                                                    name='amount'
                                                    type='text'
                                                    icon='moneyAmount'
                                                    setFieldValue={setFieldValue}
                                                    value={values.amount}
                                                />
                                                <ErrorMessage name='amount' className='error-field' component='div' />
                                            </div>
                                            <div className='form-input'>
                                                <InputBox
                                                    label='Auto recharge'
                                                    name='auto_recharge'
                                                    type='text'
                                                    icon='moneyAmount'
                                                    setFieldValue={setFieldValue}
                                                    value={values.auto_recharge}
                                                    className={errors.auto_recharge && 'input-error'}
                                                />
                                                <div className='helper-text'>{errors.auto_recharge}</div>
                                                <ErrorMessage name='auto_recharge' className='error-field' component='div' />
                                            </div>
                                            <div className='form-input'>
                                                <InputBox
                                                    label='Min balance'
                                                    name='minimum_balance'
                                                    icon='moneyAmount'
                                                    type='text'
                                                    setFieldValue={setFieldValue}
                                                    value={values.minimum_balance}
                                                    className={errors.minimum_balance && 'input-error'}
                                                />
                                                <div className='helper-text'>{errors.minimum_balance}</div>
                                                <ErrorMessage name='minimum_balance' className='error-field' component='div' />
                                            </div>
                                        </div>

                                    }

                                    <div className='form-input'>
                                        <SelectBox
                                            values={values.payment_method_id}
                                            name='payment_method_id'
                                            icon='cardBlack'
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            label='Payment method'
                                            withIcon
                                            statuses={paymentMethods.map(paymentMethod => ({
                                                    value: paymentMethod?.id,
                                                    label: paymentMethod?.type,
                                                }
                                            ))}
                                            active={values.payment_methods !== ''}
                                        />
                                        <ErrorMessage name='payment_methods[value]' className='error-field' component='div' />
                                    </div>
                                <div className='bottom-button'>
                                    <div className='btn-cancel' onClick={() => close()}>cancel</div>
                                    <button type='submit' className='btn-save'>save</button>
                                </div>
                            </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};

export default WalletSettings;