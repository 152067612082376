import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Modal from '../../../ui/modal';
import {renderIcon} from '../../../../utils/renderIcon';

import './styles.scss';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }

        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const ChangeTags = ({closeModal, tags, deviceTags, addTag, deviceId, removeTag}) => {
    const [search, setSearch] = useState('');
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);
    const handleSearch = value => {
        const checked = tags.filter(item => (item.name.toLowerCase()).indexOf(value.target.value.toLowerCase()) !== -1);

        setSearch(value.target.value);
        setFilteredTags(checked);
    };
    const [filteredTags, setFilteredTags] = useState(tags || []);
    const [checkedTags, setCheckedTags] = useState(deviceTags.map(item => parseInt(item.id)) || []);
    const [isShowListTags, setIsShowListTags] = useState(false);

    useEffect(() => {
        setCheckedTags(deviceTags.map(item => parseInt(item.id)) || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceTags]);

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        }, 300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({'change-tag': true, open: flag})} ref={wrapperRef}>
                <div className='title'>Change tags</div>
                <div className='input-box'>
                    <input
                        type='text'
                        placeholder='Search to add new tag'
                        value={search}
                        onChange={(e) => handleSearch(e)}
                        onFocus={() => setIsShowListTags(true)}
                    />
                    <div className='icons'>
                        {search ?
                            (<div onClick={() => {
                                setSearch('');
                                setFilteredTags(tags);
                            }}>{renderIcon('close')}</div>)
                            :
                            renderIcon('search')
                        }
                    </div>
                </div>
                <div className={classNames({'assigned-tag': true, active: !isShowListTags})}>
                    <div className='assigned-name'>Assigned Tags</div>
                    <div className='assigned-list'>
                        {filteredTags.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={classNames({tag: true, active: checkedTags.indexOf(item.id) !== -1})}
                                >
                                    <div className='name'>{item.name}</div>
                                    <div
                                        className='esc'
                                        onClick={() => checkedTags.indexOf(item.id) !== -1 ? removeTag(deviceId, item.id) : addTag(deviceId, item.id)}
                                    >
                                        {renderIcon(checkedTags.indexOf(item.id) !== -1 ? 'close' : 'add')}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={classNames({'list-tags': true, active: isShowListTags})}>
                    {filteredTags.map((item, index) => {
                        return (
                            <div className='item' key={index}>
                                {checkedTags.indexOf(item.id) !== -1 ? (
                                    <div className='remove' onClick={() => removeTag(deviceId, item.id)}>
                                        {renderIcon('round-plus')}
                                    </div>
                                ) : null}
                                <div className='name'>{item.name}</div>
                                <div className='icons'>
                                    {checkedTags.indexOf(item.id) !== -1 ? (
                                        <div className='check'>{renderIcon('round-check')}</div>
                                    ) : (
                                        <div className='plus' onClick={() => addTag(deviceId, item.id)}>
                                            {renderIcon('round-plus')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className='bottom-nav'>
                    <div className='cancel' onClick={() => close()}>cancel</div>
                </div>
            </div>
        </Modal>
    );
};

ChangeTags.propTypes = {
    addTag: PropTypes.func,
    closeModal: PropTypes.func,
    removeTag: PropTypes.func,
    tags: PropTypes.array,
    deviceTags: PropTypes.array,
    deviceId: PropTypes.string,
};

export default ChangeTags;
