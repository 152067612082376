import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import HeaderTab from '../../../ui/headerTabs';
import Breadcrumbs from '../../../ui/breadcrumbs';
import Pagination from '../../../ui/pagination';
import PageContainer from '../../../ui/pageContainer';
import ChangeTags from '../../../ui/modals/changeTags';
import ChangeStatus from '../../../ui/modals/changeStatus';
import ChangePlan from '../../../ui/modals/changePlan';
import RemoveItem from '../../../ui/modals/removeItem';
import Loader from '../../../ui/loader';
import LiveAction from '../../../ui/modals/liveAction';

import { getCountLines, heightLineTrLiveActionDeviceTable, optionalDeviceTabs } from '../../../../constants/app';

import {
    getDevice,
    getStatuses,
    changeStatusDevice,
    changePlanDevice,
    getTags,
    addTag,
    removeTag,
    removeLiveAction,
    addLiveAction,
} from '../../../../actions/devices';
import { getLiveActionDevice } from '../../../../actions/liveAction';
import { getPlans } from '../../../../actions/plans';

import { renderIcon } from '../../../../utils/renderIcon';
import { useWindowWidth } from '../../../../utils/useWindowWidth';

import history from '../../../../utils/history';

import './styles.scss';

const DeviceInformation = ({
    actions: {
        getDevice,
        getStatuses,
        changeStatusDevice,
        getPlans,
        changePlanDevice,
        getTags,
        addTag,
        removeTag,
        removeLiveAction,
        getLiveActionDevice,
        addLiveAction,
    },
    match: {
        params: {
            id,
        },
    },
    device,
    loadingDevice,
    loadingLiveAction,
    loadingAllLiveAction,
    liveActions,
    statuses,
    plans,
    tags,
}) => {
    const [isOpenChangeStatusModal, setIsOpenChangeStatusModal] = useState(false);
    const [isOpenChangeTags, setIsOpenChangeTags] = useState(false);
    const [isOpenChangePlanModal, setIsOpenChangePlanModal] = useState(false);
    const [isOpenRemoveItem, setIsOpenRemoveItem] = useState(false);
    const [isOpenLiveAction, setIsOpenLiveAction] = useState(false);
    const [currentPagination, setCurrentPagination] = useState(1);
    const [countLines, setCountLines] = useState(0);
    const [liveActionId, setLiveActionId] = useState(null);
    const [devicePlan, changeDevicePlan] = useState(!!device.plan_info);
    const { width: windowWidth } = useWindowWidth();
    const [localLiveAction, setLocalLiveAction] = useState(device.live_actions || []);
    const table = useRef(null);
    const tableHeader = useRef(null);

    const handleEditStatusModalOpen = () => {
        if (device.plan_info) return setIsOpenChangeStatusModal(!isOpenChangeStatusModal);
        else return setIsOpenChangePlanModal(!isOpenChangePlanModal);
    }

    useEffect(() => {
        getDevice(id);
        getStatuses();
        getPlans();
        getTags();
        getLiveActionDevice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLocalLiveAction([]);
        setTimeout(() => {
            setCountLines(
                getCountLines(
                    table.current.clientHeight,
                    tableHeader.current.clientHeight,

                    heightLineTrLiveActionDeviceTable,
                )
            );
            setLocalLiveAction(device.live_actions);
        },0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination, windowWidth]);

    useEffect(() => {
        setLocalLiveAction(device.live_actions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device]);

    return (
        <div className='device-information-box'>
            {isOpenChangeTags &&
                <ChangeTags
                    closeModal={setIsOpenChangeTags}
                    tags={tags.records}
                    deviceTags={device.tags}
                    addTag={addTag}
                    deviceId={id}
                    removeTag={removeTag}
                />
            }
            {isOpenChangeStatusModal &&
                <ChangeStatus
                    closeModal={setIsOpenChangeStatusModal}
                    statuses={statuses}
                    changeStatus={changeStatusDevice}
                    deviceId={id}
                    statusId={device.status_id}
                />
            }

            {isOpenChangePlanModal &&
                <ChangePlan
                    setIsOpenChangeStatusModal={setIsOpenChangeStatusModal}
                    closeModal={setIsOpenChangePlanModal}
                    plans={plans.records}
                    deviceId={id}
                    currentPlan={device.plan_info}
                    changePlan={changePlanDevice}
                />
            }
            {isOpenRemoveItem && (
                <RemoveItem
                    remove={removeLiveAction}
                    removeLiveActionId={liveActionId}
                    id={device.id}
                    closeModal={setIsOpenRemoveItem}
                />
            )}
            {isOpenLiveAction && localLiveAction && loadingAllLiveAction && (
                <LiveAction
                    deviceAction={localLiveAction}
                    liveActions={liveActions.records}
                    addLiveAction={addLiveAction}
                    id={device.id}
                    setIsOpenLiveAction={setIsOpenLiveAction}
                />
            )}
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <HeaderTab optionTabs={optionalDeviceTabs(id)} activeTab={0} />
                <div className='account-detail'>
                    <div className='device-information'>
                        <div className='box-info'>
                            <div className='title'>Device Information</div>
                            <div className='detail'>
                                {loadingDevice
                                    ?
                                        (
                                            <div className='load-box'>
                                                <Loader orange />
                                            </div>
                                        )
                                    :
                                        (
                                            <>
                                                <div className='line'>
                                                    <div className='name'>ICCID:</div>
                                                    <div className='value'>{device.sim}</div>
                                                </div>
                                                <div className='line'>
                                                    <div className='name'>Phone:</div>
                                                    <div className='value'>{device.phone}</div>
                                                </div>
                                                <div className='line'>
                                                    <div className='name'>IP Address:</div>
                                                    <div className='value'>{device.ip}</div>
                                                </div>
                                                <div className='line'>
                                                    <div className='name'>Type:</div>
                                                    <div className='value'>{device.sim_type}</div>
                                                </div>
                                                <div className='line'>
                                                    <div className='name'>Plan:</div>
                                                    <div className='value'>
                                                        <div className='plan-name'>{device.plan_info ? device.plan_info.name : 'Not chosen'}</div>
                                                        <div className='btn-edit' onClick={() => setIsOpenChangePlanModal(!isOpenChangePlanModal)}>(Edit)</div>
                                                    </div>
                                                </div>
                                                <div className='line'>
                                                    <div className='name'>Status:</div>
                                                    <div className='value'>
                                                        <div className={classNames({ status: true, [statuses[device.status_id]] : true })}>{statuses[device.status_id]}</div>
                                                        <div className='btn-edit' onClick={handleEditStatusModalOpen}>(Edit)</div>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='name'>Network Status:</div>
                                                    <div className='value'>{device.network_status ? 'True' : 'False'}</div>
                                                </div>

                                                <div className='line'>
                                                    <div className='name'>Tags:</div>
                                                    <div className='value tags-box'>
                                                        {device.tags && device.tags.map((item,index) => {
                                                            return (
                                                                <span key={index}>#{item.name}</span>
                                                            );
                                                        })}
                                                        <div className='btn-edit' onClick={() => setIsOpenChangeTags(!isOpenChangeTags)}>(Edit)</div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                        <div className='box-info'>
                              <div className='title-with-btn'>
                                  <div className='title'>Live actions</div>
                                  <div
                                      className={
                                          classNames({ 'btn-add': true, 'disable': (Array.isArray(liveActions.records) && liveActions.records.length === 0) })
                                      }
                                      onClick={() => (Array.isArray(liveActions.records) && liveActions.records.length !== 0) ? setIsOpenLiveAction(true) : null }>add new</div>
                              </div>
                            {Array.isArray(liveActions.records) && liveActions.records.length === 0 && (
                                <div className='empty-localLiveAction'>There are no live actions. Please, create live action to add it to the device. You have to follow the link in order to create live action:  <span className='link-to-create' onClick={() => history.push('/live-actions/new')}>Link</span></div>
                            )}
                              <div className={classNames({ 'overflow-table': true, disable: (Array.isArray(liveActions.records) && liveActions.records.length === 0) || (localLiveAction && localLiveAction.length === 0) })} ref={table}>
                                  {(loadingDevice || loadingLiveAction) && (
                                      <div className='load-box'>
                                          <Loader orange/>
                                      </div>
                                  )}
                                  <table>
                                      <thead ref={tableHeader}>
                                      <tr>
                                          <th>Description</th>
                                          <th>Status</th>
                                          <th>Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                          {localLiveAction && localLiveAction
                                              .filter((obj, index) => ((currentPagination - 1) * countLines < index + 1 && index < (currentPagination - 1) * countLines + countLines))
                                              .map((obj, index) => {
                                                  return (
                                                      <tr key={index}>
                                                          <td>{obj.name}</td>
                                                          <td className={classNames({ green: obj.status ? 1 : 0, red: obj.status ? 0 : 1 })}>{obj.status ? 'PASS' : 'FAIL'}</td>
                                                          <td>
                                                              <div onClick={() => { setLiveActionId(obj.id); setIsOpenRemoveItem(true); }}>
                                                                  {renderIcon('trash')}
                                                              </div>
                                                          </td>
                                                      </tr>
                                                  );
                                              })
                                          }
                                      </tbody>
                                  </table>
                              </div>
                            <div className='pagination-box'>
                                {device.live_actions && (
                                    <Pagination
                                        handleClick={setCurrentPagination}
                                        total={device.live_actions.length ? Math.ceil(device.live_actions.length / countLines) : 0}
                                        current={currentPagination}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};


DeviceInformation.propTypes = {
    actions: PropTypes.shape({
        getDevice: PropTypes.func,
        getStatuses: PropTypes.func,
        changeStatusDevice: PropTypes.func,
        getPlans: PropTypes.func,
        changePlanDevice: PropTypes.func,
        getTags: PropTypes.func,
        addTag: PropTypes.func,
        removeTag: PropTypes.func,
        removeLiveAction: PropTypes.func,
        getLiveActionDevice: PropTypes.func,
        addLiveAction: PropTypes.func,
    }),
    match: PropTypes.object,
    plans: PropTypes.object,
    loadingDevice: PropTypes.bool,
    loadingAllLiveAction: PropTypes.bool,
    loadingLiveAction: PropTypes.bool,
    liveActions: PropTypes.any,
    tags: PropTypes.object,
    device: PropTypes.shape({
        plan_info: PropTypes.shape({
            name: PropTypes.string,
        }),
        live_actions: PropTypes.array,
        network_status: PropTypes.bool,
    }),
};

/**
 *
 * @param state
 * @returns {{loadingAllLiveAction: boolean, liveActions: (liveAction.deviceLiveActions|{total, actions}|Array), plans: *, loadingLiveAction: (liveAction.deviceLiveActions|{total, actions}|Array), loadingDevice: *, statuses: *, device: *, tags: Requireable<any[]> | Array | string[] | devices.device.tags | {name, id} | *}}
 */
function mapStateToProps(state) {
    return {
        device: state.devices.device,
        statuses: state.devices.statuses,
        loadingDevice: state.devices.loadingDevice,
        tags: state.devices.tags,
        plans: state.plans.plans,
        loadingLiveAction: state.devices.deviceLiveActions,
        loadingAllLiveAction: state.liveAction.loadingDeviceLiveAction,
        liveActions: state.liveAction.deviceLiveActions,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({addLiveAction: addLiveAction, removeLiveAction: removeLiveAction, changeStatusDevice: changeStatusDevice, getStatuses: getStatuses, changePlanDevice: changePlanDevice, removeTag: removeTag, getTags: getTags, addTag: addTag, getDevice: getDevice, getPlans: getPlans, getLiveActionDevice: getLiveActionDevice}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getDevice,
            getStatuses,
            changeStatusDevice,
            getPlans,
            changePlanDevice,
            getTags,
            addTag,
            removeTag,
            removeLiveAction,
            addLiveAction,
            getLiveActionDevice,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInformation);

