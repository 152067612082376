import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '../../../ui/modal';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const UpdateOtherInfo = ({ closeModal, data, updateOtherInfo }) => {
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        },300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-new-message': true, open: flag })} ref={wrapperRef}>
                <Formik
                    initialValues={{
                        title: data.title,
                        info: data.info,
                    }}
                    validationSchema={
                        Yup.object().shape({
                            title: Yup.string().required('Title is required'),
                        })
                    }
                    onSubmit={values => {
                        updateOtherInfo(data.id, values);
                    }}
                >
                    {() => (
                        <Form>
                            <div className='contact-info-box'>
                                <div className='title'>edit field</div>
                                <div className='input-box'>
                                    <Field component='input' name='title' />
                                    <ErrorMessage name='title' className='error-field' component='div' />
                                </div>
                                <div className='textarea-box'>
                                    <Field component='textarea' name='info' placeholder='Field text...' />
                                </div>
                                <div className='btn-box centered'>
                                    <div className='btn-cancel'
                                         onClick={() => close()}
                                    >cancel</div>
                                    <button type='submit' className='btn-save'>CHANGE</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

UpdateOtherInfo.propTypes = {
    closeModal: PropTypes.func,
    updateOtherInfo: PropTypes.func,
    data: PropTypes.shape({
        title: PropTypes.string,
        info: PropTypes.string,
    }),
};

export default UpdateOtherInfo;
