import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import Modal from '../../../ui/modal';

import { renderIcon } from '../../../../utils/renderIcon';

import './style.scss';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const LiveAction = ({ deviceAction, liveActions, addLiveAction, id, setIsOpenLiveAction }) => {
    const [localDeviceAction, setLocalDeviceAction] = useState(deviceAction || []);
    const [localLiveAction] = useState(liveActions || []);
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);
    const removeDeviceElement = (id) => {
        const newArr = localDeviceAction.filter(item => item.id !== id);

        setLocalDeviceAction(newArr);
    };
    const removeLiveElement = (id) => {
        const newElem = localLiveAction.find(item => item.id === id);
        setLocalDeviceAction(prevState => [...prevState, newElem]);
    };

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            setIsOpenLiveAction(false);
        }, 300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-live-action': true, open: flag })} ref={wrapperRef}>
                <div className='title'>live actions</div>
                <div className='description'>Tap on item to move it between lists</div>
                <div className='info'>
                    <div className='info-scroll'>
                        <div className='arrow-box'>{renderIcon('arrow-right')}{renderIcon('arrow-right')}</div>
                        <div className='box'>
                            <div className='name'>Available</div>
                            <div className='memo'>
                                <div className='overflow'>
                                     {localLiveAction && _.xorBy(localLiveAction, localDeviceAction, 'id').map((item, index) => {
                                        return (
                                            <div className='item' key={index} onClick={() => removeLiveElement(item.id)}>
                                                <div>{item.name}</div>
                                                {renderIcon('arrow-right')}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='name'>Assigned</div>
                            <div className='memo'>
                                <div className='overflow'>
                                    {localDeviceAction && localDeviceAction.map((item, index) => {
                                        return (
                                            <div className='item second' key={index} onClick={() => removeDeviceElement(item.id)}>
                                                <div>{item.name}</div>
                                                {renderIcon('arrow-right')}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='btn-box centered'>
                    <div className='btn-cancel' onClick={() => close()}>cancel</div>
                    <div className='btn-save' onClick={() => { addLiveAction(id,localDeviceAction.map(item => item.id)); close(); }}>save</div>
                </div>
            </div>
        </Modal>
    );
};

LiveAction.propTypes = {
    deviceAction: PropTypes.array,
    liveActions: PropTypes.array,
    id: PropTypes.number,
    addLiveAction: PropTypes.func,
    setIsOpenLiveAction: PropTypes.func,
};

export default LiveAction;
