import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';

import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import Loader from '../../../ui/loader';
import RemoveItem from '../../../ui/modals/removeItem';
import Warn from '../../../ui/modals/warn';
import FormLiveAction from '../formLiveAction';

import {
    getLiveAction,
    updateLiveAction,
    getRules,
    getActions,
    setRuleAction,
    updateRuleAction,
    removeRuleAction,
} from '../../../../actions/liveAction';

import './styles.scss';

const LiveActionDetail = ({
    actions: {
        getLiveAction,
        updateLiveAction,
        getRules,
        getActions,
        setRuleAction,
        updateRuleAction,
        removeRuleAction,
    },
    match: {
        params: {
            id,
        },
    },
    loadingLiveAction,
    liveAction,
    loadingUpdateLiveAction,
    loadingUpdateRuleAction,
    loadingRules,
    loadingSetRuleAction,
    rules,
    allActions,
    loadingActions,
}) => {
    const [localRules, setLocalRules] = useState([]);
    const [localActions, setLocalActions] = useState([]);
    const [isOpenRemoveItem, setIsOpenRemoveItem] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [detectActionForm, setDetectActionForm] = useState(false);

    useEffect(() => {
        getLiveAction(id);
        getRules();
        getActions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if(Array.isArray(rules.records)) {
            setLocalRules(rules.records.map(item => ({ value: item.id, label: item.name })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rules]);

    useEffect(() => {
        if(Array.isArray(allActions)) {
            setLocalActions(allActions.map(item => ({ value: item.id, label: item.name })));
        }
        // eslint-allActions-next-line react-hooks/exhaustive-deps
    }, [allActions]);

    return (
        <>
            <NavigationPrompt when={ detectActionForm }>
                {({ onConfirm, onCancel }) => (
                    <Warn
                        text='Do you really want to leave this page without saved form (rule, action)?'
                        closeModal={onCancel}
                        onConfirm={onConfirm}
                    />
                )}
            </NavigationPrompt>
            <div className='live-action-detail'>
                <div className="live-actions-header">
                    <div className="header-container">
                        <Breadcrumbs/>
                    </div>
                </div>
                <PageContainer>
                    { isOpenRemoveItem && (
                        <RemoveItem
                            remove={removeRuleAction}
                            id={deleteId}
                            closeModal={setIsOpenRemoveItem}
                        />
                    )}
                    <div className='box-load-form'>
                        {loadingLiveAction && (
                            <div className='load-box'>
                                <Loader orange />
                            </div>
                        )}
                        {!loadingLiveAction &&
                            <FormLiveAction
                                action={updateLiveAction}
                                liveAction={liveAction}
                                id={id}
                                loading={loadingUpdateLiveAction}
                                setDetectActionForm={setDetectActionForm}
                                detectActionForm={detectActionForm}
                                loadingSetRuleAction={loadingSetRuleAction}
                                loadingUpdateRuleAction={loadingUpdateRuleAction}
                                updateRuleAction={updateRuleAction}
                                setRuleAction={setRuleAction}
                                localRules={localRules}
                                localActions={localActions}
                                removeRuleAction={removeRuleAction}
                            />
                        }
                    </div>
                </PageContainer>
            </div>
        </>
    );
};

/**
 *
 * @param state
 * @returns {{loadingSetRuleAction: boolean, liveAction: (initialState.liveAction|{}|liveAction|{rules}), loadingUpdateRuleAction: boolean, loadingUpdateLiveAction: boolean, allActions: *, loadingLiveAction: boolean, rules: *, loadingRules: boolean, loadingActions: boolean}}
 */
function mapStateToProps(state) {
    return{
        loadingLiveAction: state.liveAction.loadingLiveAction,
        loadingUpdateLiveAction: state.liveAction.loadingUpdateLiveAction,
        liveAction: state.liveAction.liveAction,
        rules: state.liveAction.rules,
        allActions: state.liveAction.actions,
        loadingActions: state.liveAction.loadingActions,
        loadingRules: state.liveAction.loadingRules,
        loadingUpdateRuleAction: state.liveAction.loadingUpdateRuleAction,
        loadingSetRuleAction: state.liveAction.loadingSetRuleAction,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({removeRuleAction: removeRuleAction, updateLiveAction: updateLiveAction, getLiveAction: getLiveAction, getActions: getActions, getRules: getRules, updateRuleAction: updateRuleAction, setRuleAction: setRuleAction}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getLiveAction,
            updateLiveAction,
            getRules,
            getActions,
            setRuleAction,
            updateRuleAction,
            removeRuleAction,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveActionDetail);
