import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import img from '../../../../assets/img/verification.png';
import Modal from '../../../ui/modal';

import './style.scss';


const Verification = ({ closeModal }) => {
    return (
            <Modal>
                <div className={classNames({ 'bg-overlay': true, open: true })}>
                    <div id='openModal' className='modal'>
                        <img className={'img'} src={img} alt={img}/>
                        <div className='modal-header'>
                            <h3 className='modal-title'>SUCCESS</h3>
                            <p className='modal-message'>Your email has been verified!</p>
                        </div>
                        <div className='modal-body'>
                            <button type='button' className='btn-secondary' data-dismiss='modal' onClick={closeModal}>
                                <p className='btn-content'>Continue</p>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
    );
};

Verification.propTypes = {
    closeModal: PropTypes.func,
};

export default Verification;
