import * as types from '../constants';
import { callApi } from '../utils/client';
import history from '../utils/history';

/**
 *
 * @returns {Function}
 */
export function getLiveActionDevice() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_LIVE_ACTION_DEVICE,
        });

        return callApi('live-actions', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_LIVE_ACTION_DEVICE_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_LIVE_ACTION_DEVICE_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param size
 * @param page
 * @returns {Function}
 */
export function getAllLiveActions(size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_ALL_LIVE_ACTIONS,
        });

        return callApi(`live-actions/company-live-action?size=${size}&page=${page}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_ALL_LIVE_ACTIONS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_ALL_LIVE_ACTIONS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param fields
 * @returns {Function}
 */
export function setLiveAction(fields) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SET_LIVE_ACTION,
        });

        return callApi('live-actions', 'POST', fields)
            .then(json => {
                dispatch({
                    type: types.FETCH_SET_LIVE_ACTION_SUCCESS,
                    payload: json,
                });
                if (json.id) { history.push(`/live-actions/${json.id}`); }
            }).catch(error => {
            dispatch({
                type: types.FETCH_SET_LIVE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function getLiveAction(id = 1) {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_LIVE_ACTION,
        });

        return callApi(`live-actions/${id}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_LIVE_ACTION_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_LIVE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param field
 * @returns {Function}
 */
export function updateLiveAction(id = 1, field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_UPDATE_LIVE_ACTION,
        });

        return callApi(`live-actions/${id}`, 'PUT', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_UPDATE_LIVE_ACTION_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_UPDATE_LIVE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param size
 * @param page
 * @returns {Function}
 */
export function removeLiveAction(id = 1, size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_LIVE_ACTION,
        });

        return callApi(`live-actions/${id}?size=${size}&page=${page}`, 'DELETE')
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_LIVE_ACTION_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_LIVE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param ids
 * @param size
 * @param page
 * @returns {Function}
 */
export function removeLiveActions(ids = [1], size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_LIVE_ACTIONS,
        });

        return callApi(`live-actions?size=${size}&page=${page}`, 'DELETE', { live_actions: ids })
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_LIVE_ACTIONS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_LIVE_ACTIONS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function getRules() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_ALL_RULES,
        });

        return callApi('rules', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_ALL_RULES_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_ALL_RULES_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function getActions() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_ALL_ACTIONS,
        });

        return callApi('actions', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_ALL_ACTIONS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_ALL_ACTIONS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param field
 * @param successCallback
 * @returns {Function}
 */
export function setRuleAction(field, successCallback = () => {}) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SET_RULE_ACTION,
        });

        return callApi('live-actions/rule', 'POST', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_SET_RULE_ACTION_SUCCESS,
                    payload: json,
                });
                successCallback(json);
            }).catch(error => {
            dispatch({
                type: types.FETCH_SET_RULE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param field
 * @param successCallback
 * @returns {Function}
 */
export function updateRuleAction(field, successCallback = () => {}) {
    return dispatch => {
        dispatch({
            type: types.FETCH_UPDATE_RULE_ACTION,
        });

        return callApi('live-actions/rule', 'PUT', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_UPDATE_RULE_ACTION_SUCCESS,
                    payload: json,
                });
                successCallback(json);
            }).catch(error => {
            dispatch({
                type: types.FETCH_UPDATE_RULE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function removeRuleAction(id = 1) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_RULE_ACTION,
        });

        return callApi(`live-actions/rule/${id}`, 'DELETE')
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_RULE_ACTION_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_RULE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}
