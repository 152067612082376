import React, { useRef } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import * as PropTypes from 'prop-types';

import InputBox from '../../../../ui/inputBox';
import Loader from '../../../../ui/loader';

const BillingAddressForm = ({ billingAddress, updateAddress, updateLoading, companyId }) => {
    const { id, first_name, last_name, phone, alt_phone, email, country, zip, city, address, alt_address } = billingAddress;

    return (
        <Formik
            initialValues={{
                first_name,
                last_name,
                phone,
                alt_phone,
                email,
                country,
                zip,
                city,
                address,
                alt_address,
            }}
            validationSchema={
                Yup.object().shape({
                    first_name: Yup.string().required('First name is required!'),
                    last_name: Yup.string().required('Last name is required!'),
                    phone: Yup.string().required('Phone is required!'),
                    email: Yup.string().required('E-mail is required!'),
                    country: Yup.string().required('Country is required!'),
                    zip: Yup.string().required('Zip Code is required!'),
                    city: Yup.string().required('City is required!'),
                    address: Yup.string().required('Address is required!'),
                })
            }
            onSubmit={values => {
                const field = { ...values, type: 0, company_id: companyId };

                updateAddress(id, field);
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
              }) => (
                <Form>
                    {updateLoading && (
                        <div className='update-box'>
                            <Loader orange />
                        </div>
                    )}
                    <div className='contact-info-box'>
                        <div className='half-line'>
                            <div className='form-input'>
                                <InputBox
                                    label='First name'
                                    icon='men'
                                    name='first_name'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                    value={values.first_name}
                                    requiredField
                                />
                                <ErrorMessage name='first_name' className='error-field' component='div' />
                            </div>
                            <div className='form-input'>
                                <InputBox
                                    label='Last name'
                                    icon='men'
                                    name='last_name'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                    value={values.last_name}
                                    requiredField
                                />
                                <ErrorMessage name='last_name' className='error-field' component='div' />
                            </div>
                            <div className='form-input'>
                                <InputBox
                                    label='Phone number'
                                    icon='phone'
                                    name='phone'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                    value={values.phone}
                                    requiredField
                                    maskPhone
                                />
                                <ErrorMessage name='phone' className='error-field' component='div' />
                            </div>
                            <div className='form-input'>
                                <InputBox
                                    label='Alt. Phone Number'
                                    icon='phone'
                                    name='alt_phone'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                    value={values.alt_phone}
                                />
                            </div>
                        </div>
                        <div className='half-line'>
                            <div className='form-input'>
                                <InputBox
                                    label='E-mail'
                                    icon='email'
                                    name='email'
                                    type='email'
                                    setFieldValue={setFieldValue}
                                    value={values.email}
                                    requiredField
                                />
                                <ErrorMessage name='email' className='error-field' component='div' />
                            </div>
                            <div className='form-input'>
                                <InputBox
                                    label='Country'
                                    icon='earth'
                                    name='country'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                    value={values.country}
                                    requiredField
                                />
                                <ErrorMessage name='country' className='error-field' component='div' />
                            </div>
                            <div className='form-input'>
                                <InputBox
                                    label='Zip Code'
                                    icon='zipCode'
                                    name='zip'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                    value={values.zip}
                                    requiredField
                                />
                                <ErrorMessage name='zip' className='error-field' component='div' />
                            </div>
                            <div className='form-input'>
                                <InputBox
                                    label='City, State/Territory'
                                    icon='city'
                                    name='city'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                    value={values.city}
                                    requiredField
                                />
                                <ErrorMessage name='city' className='error-field' component='div' />
                            </div>
                        </div>
                        <div className='half-line'>
                            <InputBox
                                label='Address line'
                                icon='house'
                                name='address'
                                type='text'
                                setFieldValue={setFieldValue}
                                value={values.address}
                                requiredField
                            />
                            <ErrorMessage name='address' className='error-field' component='div' />
                            <InputBox
                                label='Alt. Address line'
                                icon='house'
                                name='alt_address'
                                type='text'
                                setFieldValue={setFieldValue}
                                value={values.alt_address}
                            />
                        </div>
                    </div>
                    <button type='submit' className='btn-send-form'>Update</button>
                </Form>
            )}
        </Formik>
    );
};

BillingAddressForm.propTypes = {
    billingAddress: PropTypes.object,
    updateAddress: PropTypes.func,
    updateLoading: PropTypes.bool,
};

export default BillingAddressForm;
