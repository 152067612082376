import * as types from '../constants';
import { callApi } from '../utils/client';
import history from '../utils/history';

import { NotificationManager } from 'react-notifications';
import {FETCH_GET_ALL_DEVICES} from "../constants";
import successAlert from '../utils/successAlert';

const showNotification = (message) => {
    NotificationManager.info(message, undefined, 1000 * 60);
};

/**
 *
 * @param size
 * @param page
 * @param searchDevices
 * @returns {Function}
 */
export function getDevices(size = 1, page = 0, searchDevices = '') {
    return dispatch => {
        dispatch({
            type: types.FETCH_DEVICES,
        });

        return callApi(`devices?size=${size}&page=${page}&query=${searchDevices}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_DEVICES_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_DEVICES_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function getAllCompaniesData(id, size = 10, searchCompany = '') {
    return dispatch => {
        dispatch({
            type: types.FETCH_COMPANIES_DATA,
        });

        return callApi(`company?page=${id}&size=${size}&query=${searchCompany}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_COMPANIES_DATA_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_COMPANIES_DATA_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {Function}
 */
export function getStatuses() {
    return dispatch => {
        dispatch({
            type: types.FETCH_DEVICE_STATUS,
        });

        return callApi('devices/status/list', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_DEVICE_STATUS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_DEVICE_STATUS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param ids
 * @param status
 * @returns {Function}
 */
export function changeStatusDevice(ids, status) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CHANGE_STATUS_DEVICE,
        });

        return callApi('devices/status', 'PUT', {
            ids,
            status_id: status,
        })
            .then(json => {
                successAlert('Changes saved');
                dispatch({
                    type: types.FETCH_CHANGE_STATUS_DEVICE_SUCCESS,
                    payload: Array.isArray(json) ? json[0] : json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_CHANGE_STATUS_DEVICE_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param ids
 * @param status
 * @returns {Function}
 */
export function changeStatusDevices(ids, status) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CHANGE_STATUS_DEVICES,
        });

        return callApi('devices/status', 'PUT', {
            ids,
            status_id: status,
        })
            .then(json => {
                successAlert('Changes saved');
                dispatch({
                    type: types.FETCH_CHANGE_STATUS_DEVICES_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_CHANGE_STATUS_DEVICES_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param ids
 * @param companyID
 * @returns {Function}
 */
export function changeCompanyDevices(ids, companyID) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CHANGE_COMPANY_DEVICES,
        });

        return callApi('devices/assign-devices', 'POST', {
            ids,
            company_id: companyID,
        })
            .then(json => {
                successAlert('Changes saved');
                dispatch({
                    type: types.FETCH_CHANGE_COMPANY_DEVICES_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_CHANGE_COMPANY_DEVICES_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @param csv-file
 * @returns {Function}
 */
export function assignDevices(data) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CSV_ASSIGN_DEVICES,
        });

        return callApi('devices/csv-assign-devices', 'POST', data)
            .then(json => {
                dispatch({
                    type: types.FETCH_CSV_ASSIGN_DEVICES_SUCCESS,
                    payload: json,
                });

                const message = `Successful creations ${Object(json).success}, failed creations ${Object(json).fail}`;

                history.push({
                    pathname: '/devices',
                    state: { message },
                });

                Array.isArray(message)
                    ? Object(json).message.forEach((messageData) => {
                        // repeat
                        Object.keys(messageData).forEach(key => {
                            messageData[key].map(text => showNotification(text));
                        });
                    })
                    : showNotification(message);
            }).catch(error => {
                dispatch({
                    type: types.FETCH_CSV_ASSIGN_DEVICES_FAIL,
                    payload: error.message ?? error,
                });

                Array.isArray(error)
                    ? error.message.forEach((messageData) => {
                        // repeat
                        Object.keys(messageData).forEach(key => {
                            messageData[key].map(text => showNotification(text));
                        });
                    })
                    : showNotification(error.message ?? error);
            });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function getDevice(id = 1) {
    return dispatch => {
        dispatch({
            type: types.FETCH_DEVICE,
        });

        return callApi(`devices/${id}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_DEVICE_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_DEVICE_FAIL,
                payload: error.message,
            });
        });
    };
}

export function addDevice(data) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_DEVICE,
            payload: data,
        });

        return callApi('devices', 'POST', data)
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_DEVICE_SUCCESS,
                    payload: json,
                });

                const message = 'Added new device';

                history.push({
                    pathname: '/devices',
                    state: { message },
                });

                showNotification(message);
            }).catch(error => {
                dispatch({
                    type: types.FETCH_ADD_DEVICE_FAIL,
                    payload: error.message,
                });

                Array.isArray(error)
                    ? error.map(({ message }) => showNotification(message))
                    : showNotification(error.message);
            });
    };
}

export function addDevices(data) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_DEVICES,
            payload: data,
        });
        return callApi('devices/csv-create', 'POST', data)
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_DEVICES_SUCCESS,
                    payload: json,
                });

                const message = `Successful creations ${Object(json).success}. Devices already exist ${Object(json).fail}.`;

                history.push({
                    pathname: '/devices',
                    state: { message },
                });

                showNotification(message);

                Object(json).message.forEach((messageData) => {
                    if (Array.isArray(messageData)) {
                        Object.keys(messageData).forEach(key => {
                            messageData[key].map(text => showNotification(text));
                        });
                    }
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_ADD_DEVICES_FAIL,
                    payload: error.message ?? error,
                });

                Array.isArray(error)
                    ? error.message.forEach((messageData) => {
                        Object.keys(messageData).forEach(key => {
                            messageData[key].map(text => showNotification(text));
                        });
                    })
                    : showNotification(error.message ?? error);
            });
    };
}

/**
 *
 * @param ids
 * @param plan
 * @param successCallback
 * @returns {Function}
 */
export function changePlanDevice(ids, plan, successCallback = () => {}) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CHANGE_PLAN_DEVICE,
        });

        return callApi('devices/plan', 'PUT', {
            ids,
            plan_id: plan,
        })
            .then(json => {
                const data = Array.isArray(json) ? json[0] : json;

                successAlert('Changes saved');

                dispatch({
                    type: types.FETCH_CHANGE_PLAN_DEVICE_SUCCESS,
                    payload: data,
                });
                successCallback(data);
            }).catch(error => {
            dispatch({
                type: types.FETCH_CHANGE_PLAN_DEVICE_FAIL,
                payload: error.message,
            });
        });
    };
}


/**
 *
 * @returns {Function}
 */
export function getTags() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_TAGS,
        });

        return callApi('tags', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_TAGS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_TAGS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param tag
 * @returns {Function}
 */
export function addTag(id, tag) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_TAG,
        });

        return callApi('tags/assign', 'POST', { device_id: id, tag })
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_TAG_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_ADD_TAG_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param tag
 * @returns {Function}
 */
export function removeTag(id, tag) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_TAG,
        });

        return callApi('tags/unassign', 'POST', { device_id: id, tag })
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_TAG_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_TAG_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param deviceId
 * @param liveId
 */
export function addLiveAction(deviceId, liveId) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_LIVE_ACTION,
        });

        return callApi(`devices/${deviceId}/live-action`, 'PUT', { device_id: deviceId, live_actions: liveId })
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_LIVE_ACTION_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_ADD_LIVE_ACTION_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param deviceId
 * @param liveId
 * @returns {Function}
 */
export function removeLiveAction(deviceId = 1, liveId = 1) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_LIVE_ACTION_DEVICE,
        });

        return callApi(`devices/${deviceId}/live-action/${liveId}`, 'DELETE')
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_LIVE_ACTION_DEVICE_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_LIVE_ACTION_DEVICE_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param size
 * @param page
 * @returns {Function}
 */
export function getSms(id = 1, size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_SMS,
        });

        return callApi(`sms/${id}/log?size=${size}&page=${page}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_SMS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_SMS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param message
 * @returns {Function}
 */
export function sendSms(id, message) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SEND_SMS,
        });

        return callApi(`sms/${id}/send`, 'POST', { device_id: id, message })
            .then(json => {
                dispatch({
                    type: types.FETCH_SEND_SMS_SUCCESS,
                    payload: json,
                });

                showNotification('Message sent successfully');
            }).catch(error => {
            dispatch({
                type: types.FETCH_SEND_SMS_FAIL,
                payload: error.message,
            });

            showNotification(error.status);
            if (typeof(error.message) != 'undefined' && error.message !== null) {
                showNotification(error.message);
            }
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function resetSendSms() {
    return dispatch => {
        dispatch({
            type: types.FETCH_RESET_SEND_SMS,
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function getOtherInfo(id = 1) {
    return dispatch => {
        dispatch({
            type: types.FETCH_OTHER_INFO,
        });

        return callApi(`devices/${id}/other-info`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_OTHER_INFO_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_OTHER_INFO_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param field
 * @returns {Function}
 */
export function addOtherInfo(field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_OTHER_INFO,
        });

        return callApi('device-other-info', 'POST', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_OTHER_INFO_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_ADD_OTHER_INFO_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function removeOtherInfo(id) {
    return dispatch => {
        dispatch({
            type: types.FETCH_DELETE_OTHER_INFO,
        });

        return callApi(`device-other-info/${id}`, 'DELETE')
            .then(json => {
                dispatch({
                    type: types.FETCH_DELETE_OTHER_INFO_SUCCESS,
                    payload: id,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_DELETE_OTHER_INFO_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param field
 * @returns {Function}
 */
export function updateOtherInfo(id = 1, field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_UPDATE_OTHER_INFO,
        });

        return callApi(`device-other-info/${id}`, 'PUT', field)
            .then(json => {
                successAlert('Changes saved');
                dispatch({
                    type: types.FETCH_UPDATE_OTHER_INFO_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_UPDATE_OTHER_INFO_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param size
 * @param page
 * @returns {Function}
 */
export function getManageLog(id = 1, size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_MANAGE_LOG,
        });

        return callApi(`devices/${id}/change-log?size=${size}&page=${page}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_MANAGE_LOG_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_MANAGE_LOG_FAIL,
                payload: error.message,
            });
        });
    };
}