import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import Dropdown from '../../ui/dropDown';
import Loader from '../../ui/loader';

import addDot from '../../../utils/addDot';
import { renderIcon } from '../../../utils/renderIcon';
import { LAPTOP, MOBILE } from '../../../constants/app';

import './styles.scss';

const Plan = ({ deviceIds, changePlan, idDevice, black = false, openSlide = false, planInfo, successCallback }) => {
    useEffect(() => {
        if(window.innerWidth <= LAPTOP && window.innerWidth >= MOBILE) {
            setToggleDropDown(true);
            setLaptop(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [toggleDropDown, setToggleDropDown] = useState(openSlide || false);
    const [laptop, setLaptop] = useState(true);

    if(!planInfo) {
        return <Loader orange />;
    }

    return (
        <div className={classNames({ plan: true, 'black-plan': black })}>
            <div className='plan__title'>{planInfo.name}</div>
            <Dropdown open={!black ? true : toggleDropDown}>
                <div className='plan__text'>{planInfo.description}</div>
            </Dropdown>
            <div className={classNames({ 'plan__list-container': true, distance: !black ? true : toggleDropDown })}>
                <div className='plan__list'>
                    <div className='name'>MRC</div>
                    <div className='value'>$ {addDot(planInfo.mrc)}</div>
                </div>
                <div className='plan__list'>
                    <div className='name'>SMS MO</div>
                    <div className='value'>{planInfo?.sms_mo ?? 0}</div>
                </div>
                <div className='plan__list'>
                    <div className='name'>SMS MT</div>
                    <div className='value'>{planInfo?.sms_mt ?? 0}</div>
                </div>
                <div className='plan__list'>
                    <div className='name'>Type</div>
                    <div className='value'>{planInfo.type}</div>
                </div>
            </div>
            {black && (
                <>
                    <div className={classNames({ 'btn-choose-plan': true, hide: !deviceIds })} onClick={() => deviceIds ? changePlan(deviceIds.split(','), planInfo.id, successCallback) : null}>choose plan</div>
                    {laptop && (
                        <div className='btn-slide' onClick={() => setToggleDropDown(!toggleDropDown)}>
                            <div className='name'>Details</div>
                            <div className={classNames({ icon: true, rotate: toggleDropDown })}>{renderIcon('arrow')}</div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

Plan.propTypes = {
    black: PropTypes.bool,
    openSlide: PropTypes.bool,
    planInfo: PropTypes.object,
    changePlan: PropTypes.func,
    successCallback: PropTypes.func,
    idDevice: PropTypes.number,
    deviceIds: PropTypes.string,
};

export default Plan;
