/* eslint-disable require-jsdoc */
import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';

import InputBox from '../../inputBox';
import SelectBox from '../../selectBox';
import Modal from '../../modal';
import Loader from '../../loader';

import './styles.scss';

function useOutsideAlerter(ref, close) {
        const reactSelectClassnames = [' css-1tvj958-option', ' css-1bdfte4-option'];

        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && !reactSelectClassnames.includes(event.target.className)) {
                    close();
                }
            }
        // Bind the event listener
        document.addEventListener('click', handleClickOutside);

return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    }, [close, reactSelectClassnames, ref]);
}


const AddUser = ({ closeModal, roles, organisation, addUser, id, loading, successAddUser, addUserSuccess, user = null, updateUser }) => {
    const [selectValues, setSelectValues] = useState({});
    const [selectOrganisation, setSelectOrganisation] = useState({});
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);
    const callback = data => {
        if (data.user_id) {
            close();
        }
    };


    useEffect(() => {
        const a = {};

        Object.keys(roles).forEach(item => {
            a[item] = roles[item].description;
        });
        setSelectValues(a);

        setSelectOrganisation(organisation);

        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(successAddUser) {
            setTimeout(() => {
                addUserSuccess();
                close();
            }, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successAddUser]);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        },300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'add-user-modal': true, open: flag })} ref={wrapperRef}>
                <div className='title'>add new user</div>
                <div className='form-container'>
                    {Object.keys(selectValues).length > 0 && (
                        <Formik
                            initialValues={{
                                username: user ? user.username : '',
                                first_name: user ? user.first_name : '',
                                last_name: user ? user.last_name : '',
                                email: user ? user.email : '',
                                roleId: user ? { value: user.role, label: selectValues[user.role] } : '',
                                organisation: user
                                    ? {
                                    value: user.company_id,
                                    label: selectOrganisation.find(item => item.id === user.company_id).name,
                                }
                                : '',
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    first_name: Yup.string().required('First name is required'),
                                    last_name: Yup.string().required('Last name is required'),
                                    email: Yup.string().required('Email is required'),
                                    roleId: Yup.object().shape({
                                        value: Yup.string().required('Type is required'),
                                    }),
                                    organisation: Yup.object().shape({
                                        value: Yup.string().required('Type is required'),
                                    }),
                                })
                            }
                            onSubmit={(values, { resetForm }) => {
                                const field = { ...values, role: values.roleId.value, organization_id: values.organisation.value, company_id: values.organisation.value };

                                field.url = window.location.href;
                                delete field.roleId;
                                delete field.organisation;

                                if (user && Object.keys(user).length > 0) {
                                    updateUser(user.user_id, field, callback);
                                } else {
                                    addUser(field, values.organisation.value);
                                    resetForm();
                                }
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  setFieldValue,
                                  setFieldTouched,
                              }) => (
                                <Form>
                                    <div className='contact-info-box'>
                                        <div className='form-input'>
                                            <InputBox
                                                label='Username'
                                                icon='men'
                                                name='username'
                                                type='text'
                                                setFieldValue={setFieldValue}
                                                value={values.user_name}
                                            />
                                            <ErrorMessage name='user_name' className='error-field' component='div' />
                                        </div>
                                        <div className='form-input'>
                                            <InputBox
                                                label='First name'
                                                icon='men'
                                                name='first_name'
                                                type='text'
                                                setFieldValue={setFieldValue}
                                                value={values.first_name}
                                            />
                                            <ErrorMessage name='first_name' className='error-field' component='div' />
                                        </div>
                                        <div className='form-input'>
                                            <InputBox
                                                label='Last name'
                                                icon='men'
                                                name='last_name'
                                                type='text'
                                                setFieldValue={setFieldValue}
                                                value={values.last_name}
                                            />
                                            <ErrorMessage name='last_name' className='error-field' component='div' />
                                        </div>
                                        <div className='form-input'>
                                            <InputBox
                                                maskPhone={true}
                                                label='Phone'
                                                icon='phone'
                                                name='phone'
                                                type='phone'
                                                setFieldValue={setFieldValue}
                                                value={values.phone}
                                            />
                                            <ErrorMessage name='phone' className='error-field' component='div' />
                                        </div>
                                        <div className='form-input'>
                                            <InputBox
                                                label='E-mail'
                                                icon='email'
                                                name='email'
                                                type='email'
                                                setFieldValue={setFieldValue}
                                                value={values.email}
                                            />
                                            <ErrorMessage name='email' className='error-field' component='div' />
                                        </div>
                                        <div className='form-input'>
                                            <SelectBox
                                                values={values.roleId}
                                                name='roleId'
                                                icon='role'
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                label='Role'
                                                withIcon
                                                statuses={selectValues}
                                                active={values.roleId !== ''}
                                            />
                                            <ErrorMessage name='roleId[value]' className='error-field' component='div' />
                                        </div>
                                        <div className='form-input'>
                                            <SelectBox
                                                values={values.organisation}
                                                name='organisation'
                                                icon='role'
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                label='Organisation'
                                                withIcon
                                                stat={selectOrganisation}
                                                active={values.organisation !== ''}
                                            />
                                            <ErrorMessage name='organisation[value]' className='error-field' component='div' />
                                        </div>
                                    </div>
                                    <div className='bottom-button'>
                                        <div className='btn-cancel' onClick={() => close()}>cancel</div>
                                        {loading ? (
                                            <div className='btn-load'>
                                                <Loader />
                                            </div>
                                        ) : <button type='submit' className='btn-save'>save</button>
                                        }
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </Modal>
    );
};

AddUser.propTypes = {
    closeModal: PropTypes.func,
    organization: PropTypes.func,
    roles: PropTypes.object,
    addUser: PropTypes.func,
    addUserSuccess: PropTypes.func,
    id: PropTypes.number,
    loading: PropTypes.bool,
    successAddUser: PropTypes.string,
    user: PropTypes.object,
};

export default AddUser;
